import React from "react";

import { Bouillon, CinemaIcon, Energy, Technis, LoungeIcon, Map, Map2 } from "./components/Assets";
import { TotalKpis } from "./components/TotalKpis";
import { Traffic } from "./components/Traffic";
import { AirQuality } from "./components/AirQuality";
import { AirQualityPollutions } from "./components/AirQualityPollutions";

const BASE_CONFIG = {
  installationId: 2368,
  organizationId: 3,
};

export const BouillonPage = () => (
  <div className="investor-page">
    <div className="investor-page__content">
      <div className="investor-page__left">
        <div className="investor-page__logos">
          <Technis />
          <Bouillon />
        </div>
        <Map />
        <TotalKpis {...BASE_CONFIG} zoneId={4837} />
      </div>
      <div className="investor-page__right">
        <div className="investor-page__box">
          <h2 className="investor-page__box-title">
            <LoungeIcon />
            lounge
          </h2>
          <Traffic {...BASE_CONFIG} zoneId={4839} />
          <AirQuality {...BASE_CONFIG} zoneId={4839} />
          <h2 className="investor-page__box-title investor-page__box-title--space">
            <CinemaIcon />
            cinema
          </h2>
          <Traffic {...BASE_CONFIG} zoneId={4838} showOccupancy limit={25} />
          <AirQualityPollutions {...BASE_CONFIG} zoneId={4838} />
        </div>
        <div className="investor-page__box">
          <h2 className="investor-page__box-title">building</h2>
          <Energy />
        </div>
      </div>
    </div>
  </div>
);

const BASE_CONFIG2 = {
  installationId: 823,
  organizationId: 3,
};

export const TechnisInvestorPage = () => (
  <div className="investor-page">
    <div className="investor-page__content">
      <div className="investor-page__left">
        <div className="investor-page__logos">
          <Technis />
        </div>
        <Map2 />
      </div>
      <div className="investor-page__right">
        <div className="investor-page__box">
          <h2 className="investor-page__box-title">
            <LoungeIcon />
            SHOWROOM
          </h2>
          <Traffic {...BASE_CONFIG2} zoneId={1033} showOccupancy limit={20} />
          <AirQuality {...BASE_CONFIG2} zoneId={1033} />
          <AirQualityPollutions {...BASE_CONFIG2} zoneId={1033} hideTitle />
        </div>
        <div className="investor-page__box">
          <h2 className="investor-page__box-title">building</h2>
          <Energy />
        </div>
      </div>
    </div>
  </div>
);
