import { useState, useEffect } from "react";

const ZONE_ID = 3901;

interface FetchImageResult {
  imgSrc: string | null;
  isLoading: boolean;
  error: Error | null;
}

export const useHeatmap = (): FetchImageResult => {
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        setIsLoading(true);

        const date = new Date();
        date.setHours(date.getHours() - 1);
        const USADate = date.toLocaleDateString("en-US", { timeZone: "America/Los_Angeles", year: "numeric", month: "2-digit", day: "2-digit" });

        const [month, day, year] = USADate.split("/");

        const formattedDate = `${year}_${month}_${day}`;

        const randomSeed = (Math.random() * 100000000).toFixed(10);

        // eslint-disable-next-line max-len
        const imageUrl = `https://storage.googleapis.com/technis-counting-retail-resources/zones/${ZONE_ID}/heatmaps/${ZONE_ID}_${formattedDate}_all.png?random=${randomSeed}`;

        console.log("HEATMAP_REFRESH", imageUrl);

        const response = await fetch(imageUrl, {
          headers: {
            "Cache-Control": "no-cache",
          },
        });
        if (!response.ok) {
          throw new Error(`Failed to fetch image. Status: ${response.status}`);
        }

        const blob = await response.blob();
        const reader = new FileReader();

        reader.onload = () => {
          const base64Data = reader.result as string;
          setImgSrc(base64Data);
          setIsLoading(false);
        };

        reader.readAsDataURL(blob);
      } catch (error) {
        setError(error as Error);
        setIsLoading(false);
      }
    };

    // Fetch image initially
    fetchImage();

    // Fetch image every 5 minutes
    const intervalId = setInterval(fetchImage, 5 * 60 * 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return { imgSrc, isLoading, error };
};
