import { isDefined, Maybe, WebAppMetrics } from "@technis/shared";
import { off, onValue, ref } from "firebase/database";
import { useEffect, useRef, useState } from "react";
import { usePrevious } from ".";
import { firebaseDb } from "../index";
import { DISCONNECT_CHECK_INTERVAL } from "../common/constants";
import { ZoneWithMoreData } from "../common/types";
import { OccupancyZone } from "../graphql/occupancy.gql";

const KpiValueFiledName = {
  [WebAppMetrics.OCCUPANCY_RATE]: "OCCUPANCY_RATE",
  [WebAppMetrics.AFFLUENCE_IN]: "AFFLUENCE_IN",
  [WebAppMetrics.INSIDE]: "INSIDE",
  [WebAppMetrics.WAITING_TIME]: "WAITING_TIME",
  [WebAppMetrics.DWELL_TIME]: "DWELL_TIME",
  [WebAppMetrics.LIMIT]: "LIMIT",
};

const getZoneUrl = (
  { id, installationId, organizationId }: Pick<OccupancyZone, "organizationId" | "installationId" | "id">,
  metric: (typeof KpiValueFiledName)[keyof typeof KpiValueFiledName],
) => `${process.env.FIREBASE_DATABASE_ROOT}/liveKPIs/${organizationId}/${installationId}/zones/${id}/${metric}`;

export const useOccupancyData = (token: string | undefined, zone: ZoneWithMoreData, type: WebAppMetrics) => {
  const metric = KpiValueFiledName[type];
  const [kpiValue, setKpiValue] = useState<number>();

  const isConnectedRef = useRef(false);

  const url = getZoneUrl(zone, metric);

  const startListening = () => {
    isConnectedRef.current = true;
    const countRef = ref(firebaseDb, url);

    onValue(
      countRef,
      (snapshot) => {
        const val: Maybe<number> = snapshot.val();

        if (isDefined(val)) {
          setKpiValue(val);
        }
      },
      (err) => {
        console.log(err);
        isConnectedRef.current = false;
      },
    );
  };

  const stopListening = () => {
    off(ref(firebaseDb, url));
    isConnectedRef.current = false;
  };

  const prevToken = usePrevious(token);

  // Logic to keep firebase alive
  useEffect(() => {
    if (prevToken && isConnectedRef.current) {
      stopListening();
    }
    startListening();
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isConnectedRef.current) {
        startListening();
      }
    }, DISCONNECT_CHECK_INTERVAL);
    return () => {
      clearInterval(interval);
      if (isConnectedRef.current) {
        stopListening();
      }
    };
  }, []);

  return kpiValue;
};
