import React, { FC } from "react";
import { KeyIndicatorV2 } from "@technis/ui";
import { MetricsProps } from "../../../../common/types";
import { formatDwellTime } from "../helpers/format";

export const MetricsPingpong: FC<MetricsProps> = ({ dataKPI }) => {
  const insideKPI = dataKPI.occupancy.pingpong;
  const dwellKPI = dataKPI.dwell.pingpong || 0;

  return (
    <div className="column-container">
      <div className="title-container">
        <span className="title-text">Ping-pong table</span>
      </div>
      <div className="kpi-column-container">
        <KeyIndicatorV2
          className="KeyIndicatorV2__wrapper"
          defaultIcon={{
            backgroundColor: "#1B46E1",
            iconColor: "#FFFFFF",
            iconName: "inside_custom",
          }}
          description="People inside"
          valueObject={{
            valueAsString: `${insideKPI}`,
          }}
        />
        <KeyIndicatorV2
          defaultIcon={{
            backgroundColor: "#009A06",
            iconColor: "#FFFFFF",
            iconName: "dwell-basic_custom",
          }}
          description="Dwell time"
          valueObject={{
            valueAsString: formatDwellTime(dwellKPI),
          }}
        />
      </div>
    </div>
  );
};
