import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import "../../../styles/foireDuTrone.scss";
import { useFoireZone } from "./data/zoneData";
import { LogoFoire } from "./components/Assets";
import { DateFoire } from "./components/Date";
import { VisitorDisplay } from "./components/VisitorDisplay";
import { useFoirePassage } from "./data/passageData";
import { KpiColumns } from "./components/KpiColumns";

export const Page = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const zoneData = useFoireZone(token);
  const passageData = useFoirePassage(token);

  return (
    <div className="foire-page">
      <div className="foire-page__logo-container">
        <LogoFoire />
      </div>
      <div className="foire-page__body">
        <DateFoire />
        <VisitorDisplay zoneData={zoneData} />
        <KpiColumns passageData={passageData} />
      </div>
      <div className="foire-page-powered-by-container">
        <span className="foire-page-powered-by-container-text">powered by</span>
        <img src="https://merckserono-corsier.technis.com/76b9a76274db3a7d85786796322e35e3.svg" alt="technis logo" className="foire-page-powered-by-container-logo" />
      </div>
    </div>
  );
};
