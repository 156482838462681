/* eslint-disable max-len */
import React from "react";

export const MapWow = () => (
  <svg width="646" height="335" viewBox="0 0 646 335" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8228_536)">
      <path
        d="M303.474 285.673V226.961H331.91H334.812V224.059V151.518H618.594H621.496V148.616V9.33594V6.43427H618.594H-80.707H-83.6087V9.33594V161.414V186.049V188.951H-80.707H-33.914V329.1V332.001H-31.0123H300.572H303.474V329.1V285.673Z"
        fill="#272734"
        stroke="#272734"
        strokeWidth="5.80333"
      />
      <path d="M76 158H114V148H299H330L331.5 224H299V286H76V158Z" fill="#4E4E68" />
      <rect x="114" y="12" width="504" height="136" fill="#4E4E68" />
      <path d="M293.14 157.197H112.414V10.9531H216.138H617.5" stroke="#636384" strokeWidth="3.482" />
      <path d="M151.176 157.801H77.0391V286.054H151.176V157.801Z" stroke="#636384" strokeWidth="3.482" />
      <path d="M329.301 223.723V146.863H615.985V9.5" stroke="#636384" strokeWidth="3.482" />
      <path d="M297.965 328.188V285.824M297.965 285.824H76.2773V157.57M297.965 285.824V223.149" stroke="#636384" strokeWidth="3.482" />
      <path d="M-81 157.9H31.0043" stroke="#636384" strokeWidth="3.482" />
      <rect x="110.102" y="110.178" width="4" height="37" fill="#272734" />
      <rect x="299.699" y="227.5" width="4" height="27.8" transform="rotate(-90 299.699 227.5)" fill="#272734" />
      <rect x="460.699" y="149.301" width="4.2" height="58" transform="rotate(-90 460.699 149.301)" fill="#272734" />
    </g>
    <rect x="150.808" y="63.9529" width="32.1905" height="32.1905" rx="16.0952" fill="#141420" />
    <rect x="150.808" y="63.9529" width="32.1905" height="32.1905" rx="16.0952" stroke="#9696C0" strokeWidth="2.47619" />
    <path
      d="M165.11 84.3956C164.494 85.1822 163.578 85.5755 162.362 85.5755C161.145 85.5755 160.229 85.1822 159.613 84.3956C158.98 83.6172 158.664 82.5023 158.664 81.0508C158.664 79.6074 158.98 78.4884 159.613 77.6938C160.237 76.9153 161.153 76.5261 162.362 76.5261C163.57 76.5261 164.486 76.9153 165.11 77.6938C165.735 78.4884 166.047 79.6074 166.047 81.0508C166.047 82.4941 165.735 83.6091 165.11 84.3956ZM161.194 83.2037C161.445 83.6496 161.835 83.8726 162.362 83.8726C162.889 83.8726 163.278 83.6496 163.529 83.2037C163.781 82.7739 163.906 82.0563 163.906 81.0508C163.906 80.0696 163.777 79.3561 163.517 78.9101C163.266 78.456 162.881 78.2289 162.362 78.2289C161.835 78.2289 161.441 78.456 161.182 78.9101C160.93 79.3561 160.805 80.0696 160.805 81.0508C160.805 82.0401 160.934 82.7577 161.194 83.2037ZM170.512 79.6642C171.468 79.6642 172.21 79.9399 172.737 80.4913C173.248 81.0508 173.504 81.7238 173.504 82.5104C173.504 83.451 173.188 84.1929 172.555 84.7362C171.914 85.2957 171.1 85.5755 170.11 85.5755C169.186 85.5755 168.416 85.3322 167.799 84.8457C167.183 84.3754 166.83 83.7145 166.741 82.8631H168.675C168.74 83.2037 168.898 83.4753 169.149 83.678C169.409 83.8807 169.729 83.9821 170.11 83.9821C170.548 83.9821 170.901 83.8483 171.168 83.5807C171.436 83.305 171.57 82.9482 171.57 82.5104C171.57 82.0725 171.436 81.7198 171.168 81.4522C170.901 81.1765 170.548 81.0386 170.11 81.0386C169.478 81.0386 169.032 81.2941 168.772 81.8049H166.875L167.726 76.7207H173.09V78.4236H169.307L168.979 80.1872C169.401 79.8385 169.912 79.6642 170.512 79.6642Z"
      fill="white"
    />
    <rect x="430.808" y="63.9529" width="32.1905" height="32.1905" rx="16.0952" fill="#141420" />
    <rect x="430.808" y="63.9529" width="32.1905" height="32.1905" rx="16.0952" stroke="#9696C0" strokeWidth="2.47619" />
    <path
      d="M445.11 84.3956C444.494 85.1822 443.578 85.5755 442.362 85.5755C441.145 85.5755 440.229 85.1822 439.613 84.3956C438.98 83.6172 438.664 82.5023 438.664 81.0508C438.664 79.6074 438.98 78.4884 439.613 77.6938C440.237 76.9153 441.153 76.5261 442.362 76.5261C443.57 76.5261 444.486 76.9153 445.11 77.6938C445.735 78.4884 446.047 79.6074 446.047 81.0508C446.047 82.4941 445.735 83.6091 445.11 84.3956ZM441.194 83.2037C441.445 83.6496 441.835 83.8726 442.362 83.8726C442.889 83.8726 443.278 83.6496 443.529 83.2037C443.781 82.7739 443.906 82.0563 443.906 81.0508C443.906 80.0696 443.777 79.3561 443.517 78.9101C443.266 78.456 442.881 78.2289 442.362 78.2289C441.835 78.2289 441.441 78.456 441.182 78.9101C440.93 79.3561 440.805 80.0696 440.805 81.0508C440.805 82.0401 440.934 82.7577 441.194 83.2037ZM452.812 81.8049H453.943V83.4956H452.812V85.3809H450.757V83.4956H446.633V81.5616L450.55 76.7207H452.812V81.8049ZM450.817 81.8049V78.752L448.348 81.8049H450.817Z"
      fill="white"
    />
    <rect x="96.2381" y="205.572" width="32.1905" height="32.1905" rx="16.0952" fill="#141420" />
    <rect x="96.2381" y="205.572" width="32.1905" height="32.1905" rx="16.0952" stroke="#9696C0" strokeWidth="2.47619" />
    <path
      d="M111.778 226.015C111.162 226.801 110.246 227.195 109.03 227.195C107.813 227.195 106.897 226.801 106.281 226.015C105.648 225.236 105.332 224.121 105.332 222.67C105.332 221.227 105.648 220.108 106.281 219.313C106.905 218.534 107.821 218.145 109.03 218.145C110.238 218.145 111.154 218.534 111.778 219.313C112.403 220.108 112.715 221.227 112.715 222.67C112.715 224.113 112.403 225.228 111.778 226.015ZM107.862 224.823C108.113 225.269 108.502 225.492 109.03 225.492C109.557 225.492 109.946 225.269 110.197 224.823C110.449 224.393 110.574 223.675 110.574 222.67C110.574 221.689 110.445 220.975 110.185 220.529C109.934 220.075 109.549 219.848 109.03 219.848C108.502 219.848 108.109 220.075 107.85 220.529C107.598 220.975 107.473 221.689 107.473 222.67C107.473 223.659 107.602 224.377 107.862 224.823ZM115.745 218.34H117.363V227H115.307V221.587H113.288V220.189L113.483 220.176C114.123 220.136 114.626 219.978 114.991 219.702C115.356 219.418 115.607 218.964 115.745 218.34Z"
      fill="white"
    />
    <rect x="206.523" y="205.572" width="32.1905" height="32.1905" rx="16.0952" fill="#141420" />
    <rect x="206.523" y="205.572" width="32.1905" height="32.1905" rx="16.0952" stroke="#9696C0" strokeWidth="2.47619" />
    <path
      d="M222.064 226.015C221.447 226.801 220.531 227.195 219.315 227.195C218.098 227.195 217.182 226.801 216.566 226.015C215.933 225.236 215.617 224.121 215.617 222.67C215.617 221.227 215.933 220.108 216.566 219.313C217.19 218.534 218.107 218.145 219.315 218.145C220.523 218.145 221.439 218.534 222.064 219.313C222.688 220.108 223 221.227 223 222.67C223 224.113 222.688 225.228 222.064 226.015ZM218.147 224.823C218.398 225.269 218.788 225.492 219.315 225.492C219.842 225.492 220.231 225.269 220.482 224.823C220.734 224.393 220.859 223.675 220.859 222.67C220.859 221.689 220.73 220.975 220.47 220.529C220.219 220.075 219.834 219.848 219.315 219.848C218.788 219.848 218.394 220.075 218.135 220.529C217.884 220.975 217.758 221.689 217.758 222.67C217.758 223.659 217.888 224.377 218.147 224.823ZM225.874 225.297H230.253V227H223.563L223.612 226.234C223.644 225.65 223.737 225.143 223.891 224.713C224.005 224.421 224.143 224.162 224.305 223.935C224.718 223.408 225.335 222.982 226.154 222.658L226.835 222.354C227.305 222.159 227.638 221.956 227.832 221.746C228.035 221.527 228.136 221.255 228.136 220.931C228.136 220.582 228.031 220.314 227.82 220.128C227.609 219.941 227.309 219.848 226.92 219.848C226.052 219.848 225.619 220.31 225.619 221.235H223.66C223.652 220.262 223.928 219.503 224.487 218.96C225.047 218.417 225.874 218.145 226.969 218.145C227.966 218.145 228.753 218.389 229.328 218.875C229.896 219.362 230.18 219.982 230.18 220.736C230.18 221.482 229.981 222.078 229.584 222.524C229.178 222.978 228.546 223.4 227.686 223.789L226.969 224.13C226.588 224.308 226.316 224.482 226.154 224.653C226 224.815 225.906 225.03 225.874 225.297Z"
      fill="white"
    />
    <rect x="238.855" y="64.2381" width="32.1905" height="32.1905" rx="16.0952" fill="#141420" />
    <rect x="238.855" y="64.2381" width="32.1905" height="32.1905" rx="16.0952" stroke="#9696C0" strokeWidth="2.47619" />
    <path
      d="M253.157 84.6808C252.541 85.4674 251.625 85.8606 250.408 85.8606C249.192 85.8606 248.276 85.4674 247.66 84.6808C247.027 83.9024 246.711 82.7874 246.711 81.3359C246.711 79.8926 247.027 78.7736 247.66 77.9789C248.284 77.2005 249.2 76.8113 250.408 76.8113C251.617 76.8113 252.533 77.2005 253.157 77.9789C253.782 78.7736 254.094 79.8926 254.094 81.3359C254.094 82.7793 253.782 83.8943 253.157 84.6808ZM249.241 83.4888C249.492 83.9348 249.881 84.1578 250.408 84.1578C250.936 84.1578 251.325 83.9348 251.576 83.4888C251.827 83.0591 251.953 82.3414 251.953 81.3359C251.953 80.3548 251.823 79.6412 251.564 79.1952C251.313 78.7411 250.927 78.5141 250.408 78.5141C249.881 78.5141 249.488 78.7411 249.229 79.1952C248.977 79.6412 248.852 80.3548 248.852 81.3359C248.852 82.3252 248.981 83.0428 249.241 83.4888ZM260.334 81.044C260.805 81.2143 261.17 81.4819 261.429 81.8468C261.697 82.2117 261.83 82.6455 261.83 83.1482C261.83 83.951 261.526 84.6038 260.918 85.1065C260.302 85.6093 259.463 85.8606 258.4 85.8606C257.217 85.8606 256.316 85.5809 255.7 85.0214C255.084 84.4619 254.776 83.6672 254.776 82.6374H256.746C256.754 83.1726 256.884 83.5618 257.135 83.8051C257.395 84.0402 257.792 84.1578 258.327 84.1578C258.765 84.1578 259.106 84.0605 259.349 83.8659C259.592 83.6632 259.714 83.3915 259.714 83.0509C259.714 82.2725 259.227 81.8833 258.254 81.8833H257.61V80.4359H258.291C259.175 80.4359 259.617 80.0913 259.617 79.402C259.617 79.102 259.507 78.8628 259.288 78.6844C259.069 78.506 258.757 78.4168 258.352 78.4168C257.914 78.4168 257.581 78.5141 257.354 78.7087C257.135 78.8952 257.01 79.1952 256.977 79.6088H255.007C255.072 77.7438 256.191 76.8113 258.364 76.8113C259.361 76.8113 260.144 77.0261 260.711 77.4559C261.287 77.8857 261.575 78.4695 261.575 79.2074C261.575 79.6047 261.461 79.9656 261.234 80.2899C261.015 80.6143 260.715 80.8656 260.334 81.044Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0_8228_536">
        <rect width="646" height="335" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
