/* eslint-disable max-len */
import React, { FC } from "react";

interface Props {
  visitorCount: number;
  captureCount: number;
  conversionCount: number;
  averageDwellTime: number;
}

export const FunnelCES: FC<Props> = ({ averageDwellTime, captureCount, conversionCount, visitorCount }) => {
  const capturePercentage = captureCount === 0 ? 0 : (captureCount / (visitorCount / 100)).toFixed(2);
  const conversionPercentage = conversionCount === 0 ? 0 : (conversionCount / (captureCount / 100)).toFixed(2);

  const minutes = (averageDwellTime / 60).toFixed(0);
  const seconds = averageDwellTime % 60;

  return (
    <svg viewBox="0 0 581 574" fill="none" xmlns="http://www.w3.org/2000/svg" className="ces2025__funnel">
      <g id="Funnel With legend">
        <path
          id="Vector"
          d="M359.835 572H299.299C299.299 572 295.671 444.192 288.995 362.743C282.397 282.238 278.005 236.22 255.507 158.622C237.174 95.388 193.039 2 193.039 2H466.095C466.095 2 422.459 95.443 404.271 158.622C381.92 236.262 377.382 282.238 370.783 362.743C364.107 444.192 359.835 572 359.835 572Z"
          fill="url(#paint0_linear_662_880)"
        />
        <path id="Vector_2" d="M185.038 2H0.00061667" stroke="#2F2F2F" strokeWidth="3" strokeDasharray="6 6" />
        <path id="Vector_3" d="M253.052 192H0.000516057" stroke="#2F2F2F" strokeWidth="3" strokeDasharray="6 6" />
        <path id="Vector_4" d="M396.08 192H264.053" stroke="white" strokeWidth="3" strokeDasharray="6 6" />
        <path id="Vector_5" d="M369.075 382H290.059" stroke="white" strokeWidth="3" strokeDasharray="6 6" />
        <path id="Vector_6" d="M281.058 382H0.000704408" stroke="#2F2F2F" strokeWidth="3" strokeDasharray="6 6" />
        <path id="Vector_7" d="M291.06 572H0.000632167" stroke="#2F2F2F" strokeWidth="3" strokeDasharray="6 6" />
        <text id="3567" fill="white" textAnchor="middle" xmlSpace="preserve" fontFamily="Inter" fontSize="32" fontWeight="bold" letterSpacing="0em">
          <tspan x="330" y="106.136">
            {visitorCount}
          </tspan>
        </text>
        <text id="669" fill="white" textAnchor="middle" xmlSpace="preserve" fontFamily="Inter" fontSize="32" fontWeight="bold" letterSpacing="0em">
          <tspan x="330" y="296.136">
            {captureCount}
          </tspan>
        </text>
        <text id="87" fill="white" textAnchor="middle" xmlSpace="preserve" fontFamily="Inter" fontSize="32" fontWeight="bold" letterSpacing="0em">
          <tspan x="330" y="486.136">
            {conversionCount}
          </tspan>
        </text>
        <text id="Visitors around the booth" fill="black" xmlSpace="preserve" fontFamily="Inter" fontSize="24" fontWeight="bold" letterSpacing="0em">
          <tspan x="0" y="34.2273">
            Visitors around &#10;
          </tspan>
          <tspan x="0" y="63.2273">
            the booth
          </tspan>
        </text>
        <text id="100%" fill="#878787" xmlSpace="preserve" fontFamily="Inter" fontSize="36" fontWeight="bold" letterSpacing="0em">
          <tspan x="0" y="104.091">
            100%
          </tspan>
        </text>
        <text id="18.7%" fill="#878787" xmlSpace="preserve" fontFamily="Inter" fontSize="36" fontWeight="bold" letterSpacing="0em">
          <tspan x="0" y="271.091">
            {capturePercentage}%
          </tspan>
        </text>
        <text id="13.2%" fill="#878787" xmlSpace="preserve" fontFamily="Inter" fontSize="36" fontWeight="bold" letterSpacing="0em">
          <tspan x="0" y="464.091">
            {conversionPercentage}%
          </tspan>
        </text>
        <text id="Capture rate" fill="black" xmlSpace="preserve" fontFamily="Inter" fontSize="24" fontWeight="bold" letterSpacing="0em">
          <tspan x="0" y="224.227">
            Capture rate
          </tspan>
        </text>
        <text id="Conversion rate" fill="black" xmlSpace="preserve" fontFamily="Inter" fontSize="24" fontWeight="bold" letterSpacing="0em">
          <tspan x="0" y="414.227">
            Conversion rate
          </tspan>
        </text>
        <path id="Vector 46" d="M401.324 192H441V572H365" stroke="#A7A7A7" strokeWidth="3" strokeLinecap="round" strokeDasharray="6 6" />
        <text id="9m36s" fill="#878787" xmlSpace="preserve" fontFamily="Inter" fontSize="36" fontWeight="bold" letterSpacing="0em">
          <tspan x="451" y="420.091">
            {minutes}m{seconds}s
          </tspan>
        </text>
        <text id="Average Dwell Time" fill="black" xmlSpace="preserve" fontFamily="Inter" fontSize="24" fontWeight="bold" letterSpacing="0em">
          <tspan x="451" y="347.227">
            Average&#10;
          </tspan>
          <tspan x="451" y="376.227">
            Dwell Time
          </tspan>
        </text>
      </g>
      <defs>
        <linearGradient id="paint0_linear_662_880" x1="329.567" y1="572" x2="329.567" y2="-188" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1ED2E3" />
          <stop offset="0.489583" stopColor="#588DE7" />
          <stop offset="1" stopColor="#8F4DEA" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export type FunnelProps = Props;
