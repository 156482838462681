import { off, onValue, ref } from "firebase/database";
import { useEffect, useRef, useState } from "react";
import { usePrevious } from ".";

import { Maybe, Organization, Passage, RetailMetrics, Zone, isDefined } from "@technis/shared";
import { firebaseDb } from "../index";
import { DISCONNECT_CHECK_INTERVAL } from "../common/constants";

interface FirebaseHookProps {
  zoneId?: Zone["id"];
  installationId: Zone["installationId"];
  organizationId?: Organization["id"];
  metric: RetailMetrics;
  token?: string;
  skip?: boolean;
  passageId?: Passage["id"];
}

const getUrl = ({
  organizationId,
  installationId,
  zoneId,
  passageId,
  metric,
}: {
  metric: RetailMetrics;
  zoneId?: Zone["id"];
  passageId?: Passage["id"];
  installationId: Zone["installationId"];
  organizationId: Organization["id"];
}) => {
  const type = zoneId ? "zones" : "passages";
  const itemId = zoneId || passageId;

  return `/${process.env.ENV}/${process.env.FIREBASE_VISION_ENDPOINT}/${organizationId}/${installationId}/${type}/${itemId}/${metric}`;
};

export const useVisionData = ({ zoneId, passageId, installationId, metric, token, organizationId }: FirebaseHookProps) => {
  const [kpiValue, setKpiValue] = useState<number>();
  const prevToken = usePrevious(token);
  const isConnectedRef = useRef(false);

  if (!organizationId) {
    return 0;
  }

  // Logic to keep firebase alive
  useEffect(() => {
    if (prevToken && isConnectedRef.current) {
      stopListening();
    }
    startListening();
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isConnectedRef.current) {
        startListening();
      }
    }, DISCONNECT_CHECK_INTERVAL);
    return () => {
      clearInterval(interval);
      if (isConnectedRef.current) {
        stopListening();
      }
    };
  }, []);

  const url = getUrl({ installationId, organizationId, metric, zoneId, passageId });

  const startListening = () => {
    isConnectedRef.current = true;
    const countRef = ref(firebaseDb, url);

    onValue(
      countRef,
      (snapshot) => {
        const val: Maybe<number> = snapshot.val();
        if (isDefined(val)) {
          setKpiValue(val);
        }
      },
      (err) => {
        console.log(err);
        isConnectedRef.current = false;
      },
    );
  };

  const stopListening = () => {
    off(ref(firebaseDb, url));
    isConnectedRef.current = false;
  };

  return kpiValue;
};
