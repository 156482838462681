import React from "react";
import { DefaultSiteInfo } from "./types";
import { RetailMetrics, roundByMetric } from "@technis/shared";
import { useVisionData } from "../../../../hooks/useVisionData";
import moment from "moment";
import { AppColor, IconCommon, IconName } from "@technis/ui";

export const Traffic = ({ installationId, organizationId, showOccupancy, zoneId, limit }: DefaultSiteInfo & { zoneId: number; showOccupancy?: boolean; limit?: number }) => {
  const inside = useVisionData({ installationId, organizationId, zoneId, metric: RetailMetrics.INSIDE }) || 0;
  const dwellRaw = useVisionData({ installationId, organizationId, zoneId, metric: RetailMetrics.DWELL }) || 0;
  const occupancy = showOccupancy && limit ? ((inside || 0) / limit) * 100 : 0;

  const duration = moment.duration(dwellRaw, "seconds");
  const hours = duration.hours();
  const minutes = duration.minutes();

  return (
    <div className="traffic">
      <h3 className="traffic__title">Traffic</h3>
      <div className="traffic__row traffic__row--kpis">
        <div className="traffic__kpis">
          <div className="traffic__kpis-icon">
            <IconCommon name={IconName.USERS} color={AppColor.WHITE} />
          </div>
          <div className="traffic__kpis-values">
            <span className="value">{inside}</span>
            <span className="unit"></span>
            <span className="metric">Visitors inside</span>
          </div>
        </div>
        <div className="traffic__kpis">
          <div className="traffic__kpis-icon">
            <IconCommon name={IconName.CLOCK_FILLED} color={AppColor.WHITE} />
          </div>
          <div className="traffic__kpis-values">
            {!!hours && (
              <>
                <span className="value">{hours}</span>
                <span className="unit">h</span>
              </>
            )}
            {!!minutes && (
              <>
                <span className="value">{minutes}</span>
                <span className="unit">min</span>
              </>
            )}
            {!hours && !minutes && (
              <>
                <span className="value">-</span>
                <span className="unit">min</span>
              </>
            )}

            <span className="metric">Dwell time</span>
          </div>
        </div>
      </div>
      {showOccupancy && (
        <div className="traffic__row">
          <div className="traffic__occupancy">
            <div className="traffic__occupancy-text">
              <span className="value">{roundByMetric(RetailMetrics.OCCUPANCY, occupancy || 0)}</span>
              <span className="unit">%</span>
              <span className="metric">Occupancy rate</span>
            </div>
            <div className="traffic__progress-bar">
              <div className="traffic__progress-bar__value" style={{ width: `${occupancy}%` }}></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
