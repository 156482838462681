import React, { FC } from "react";

interface VisitorProps {
  zoneData: {
    insideKpi: number;
    affluenceInKpi: number;
  };
}

export const VisitorDisplay: FC<VisitorProps> = ({ zoneData }) => {
  const { insideKpi, affluenceInKpi } = zoneData;

  return (
    <div className="visitor-container">
      <div className="visitor-container-column">
        <div className="visitor-container-column-kpi">{insideKpi}</div>
        <span className="visitor-container-column-description">Visiteurs en temps-réel</span>
      </div>
      <div className="visitor-container-column">
        <div className="visitor-container-column-kpi">{affluenceInKpi}</div>
        <span className="visitor-container-column-description">Visiteurs aujourd’hui</span>
      </div>
    </div>
  );
};
