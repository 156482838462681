import React, { FC } from "react";

export interface Props {
  count: number;
  onAdd: () => void;
  onRemove: () => void;
  onReset: () => void;
}

const getButtonStyle = (isDanger: boolean): React.CSSProperties => ({
  background: isDanger ? "#f44336" : "linear-gradient(234.49deg, #1ED2E3 9.25%, #588DE7 43.79%, #8F4DEA 78.34%)",
  color: isDanger ? "white" : "white",
  border: "none",
  padding: "12px 24px",
  textAlign: "center",
  textDecoration: "none",
  display: "inline-block",
  fontSize: 16,
  margin: "8px",
  cursor: "pointer",
  borderRadius: 8,
});

export const CounterCES: FC<Props> = ({ count, onAdd, onReset, onRemove }) => {
  const handleReset = async () => {
    const result = await confirm("Are you sure you want to resert the counter to 0 ? This action cannot be reverted !");
    if (result === true) {
      onReset();
    }
  };
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
      <span style={{ fontWeight: "bold", fontSize: 26, marginBottom: 20 }}>Contacts added to CRM count</span>
      <span style={{ fontWeight: "bold", fontSize: 69 }}>{count}</span>
      <button style={getButtonStyle(false)} onClick={onAdd}>
        Add 1
      </button>
      <button style={getButtonStyle(true)} onClick={onRemove}>
        Remove 1
      </button>
      <button style={getButtonStyle(true)} onClick={handleReset}>
        Reset to zero
      </button>
    </div>
  );
};
