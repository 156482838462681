import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { AppThemes } from "../components/common/Theme";
import { saveToken } from "../redux/auth/auth.actions";
import { SECONDS_IN_YEAR } from "../utils/date";

enum Environments {
  LOCAL = "local",
  DEVELOPMENT = "development",
  PRODUCTION = "production",
}

const availableDomains = {
  [Environments.LOCAL]: "localhost",
  [Environments.DEVELOPMENT]: ".technis.com",
  [Environments.PRODUCTION]: ".technis.com",
};

const COOKIE_PREFIX = process.env.ENV === "production" ? "" : `${process.env.ENV}-`;
export const COOKIE_DOMAIN = availableDomains[(process.env.ENV as Environments) || Environments.LOCAL];
export const COOKIE_THEME = "themeTechnisOccupancy";

export const useCookieToken = (cookieKey: string) => {
  const [cookies] = useCookies([]);

  // @ts-ignore
  const cookieToken: string = cookies[cookieKey];
  useEffect(() => {
    saveToken(cookieToken);
  }, [cookieToken]);
  return cookieToken;
};

export const useCookieTheme = () => {
  const [cookies, setCookie] = useCookies([]);
  const setThemeCookie = (theme: AppThemes) =>
    // @ts-ignore
    setCookie(COOKIE_THEME, theme, {
      path: "/",
      maxAge: SECONDS_IN_YEAR,
      domain: COOKIE_DOMAIN,
      secure: true,
    });
  // @ts-ignore
  return [cookies[COOKIE_THEME], setThemeCookie];
};
