import * as unset from "unset-value";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const inEnum = <T extends object>(enu: T, value: any) => Object.values(enu).includes(value);

export const isObject = (value: any) => value !== null && typeof value === "object";

// TODO: Make this <Templated>
export const omitDeep = (value: any, keys: string[] | string) => {
  if (typeof value === "undefined") {
    return {};
  }

  if (Array.isArray(value)) {
    for (let i = 0; i < value.length; i += 1) {
      value[i] = omitDeep(value[i], keys);
    }
    return value;
  }

  if (!isObject(value)) {
    return value;
  }

  if (typeof keys === "string") {
    keys = [keys];
  }

  if (!Array.isArray(keys)) {
    return value;
  }

  for (let j = 0; j < keys.length; j += 1) {
    unset(value, keys[j]);
  }

  for (const key in value) {
    if (value.hasOwnProperty(key)) {
      value[key] = omitDeep(value[key], keys);
    }
  }

  return value;
};

// eslint:disable no-any
export const removeUndefined = (object: any) => {
  if (object) {
    Object.keys(object).forEach((key) => {
      if (object[key] === undefined) {
        delete object[key];
      } else if (typeof object[key] === "object") {
        object[key] = removeUndefined(object[key]);
      }
    });
  }
  return object;
};
