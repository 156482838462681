/* eslint-disable max-len */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { MasterLayer } from "./components/MasterLayer";
import { useInsideVelazquez } from "./data/inside";

export const Page = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const data = useInsideVelazquez(token);

  useEffect(() => {
    document.title = "Velazquez Lounge - Live occupancy";
    const favicon = document.getElementById("favicon") as HTMLLinkElement;
    document.body.style.backgroundColor = "#212528";
    favicon.href =
      "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Ff228d4e96ae72b5096dc80faffb81414.cdn.bubble.io%2Ff1676982033334x530311890865857300%2FIberia-Emblem.png?w=128&h=&auto=compress&dpr=1&fit=max";
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        minWidth: "100vw",
        minHeight: "100vh",
        gap: 16,
        backgroundColor: "#212528",
      }}
    >
      <MasterLayer result={data} />
    </div>
  );
};
