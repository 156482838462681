import { RetailMetrics, WebAppMetrics } from "@technis/shared";

export const fr = {
  notFound: "Page non trouvée.",
  poweredBy: "servi par",
  persons: "Personnes",
  free: "Libre",
  occupied: "Occupé",
  scores: {
    COMFORT_SCORE: {
      "1": "Mauvais",
      "2": "Médiocre",
      "3": "Moyen",
      "4": "Bon",
      "5": "Excellent",
    },
    GENERAL_SCORE: {
      "1": "Faible",
      "2": "Moyen",
      "3": "Bon",
    },
  },
  descriptions: {
    legacy: {
      [WebAppMetrics.AFFLUENCE_IN]: "Nombre de visiteurs",
      [WebAppMetrics.DWELL_TIME]: "Temps d'attente moyen estimé",
      [WebAppMetrics.INSIDE]: "Nombre de personnes à l'intérieur",
      [WebAppMetrics.LIMIT]: "Nombre maximal de personne dans la zone",
      [WebAppMetrics.OCCUPANCY_RATE]: "Taux d'occupation",
      [WebAppMetrics.WAITING_TIME]: "Temps d'attente moyen estimé",
    },
    vision: {
      [RetailMetrics.DWELL]: "Temps de Résidence Moyen",
      [RetailMetrics.CAPTURE_RATE]: "Taux de Capture",
      [RetailMetrics.POPULARITY]: "Popularité",
      [RetailMetrics.AFFLUENCE_IN]: "Nombre de visiteurs",
      [RetailMetrics.AFFLUENCE_OUT]: "Sorties",
      [RetailMetrics.INSIDE]: "Nombre de visiteurs à l'intérieur",
      [RetailMetrics.GROUP_SIZE_IN]: "Taille de groupe",
      [RetailMetrics.GROUP_SIZE_OUT]: "Taille de groupe",
      [RetailMetrics.N_GROUPS_IN]: "Nombre de groupes",
      [RetailMetrics.N_GROUPS_OUT]: "Nombre de groupes",
      [RetailMetrics.OCCUPANCY]: "Occupation",
      [RetailMetrics.AFFLUENCE_IN_QUEUE]: "Entrées dans la queue",
      [RetailMetrics.AFFLUENCE_OUT_QUEUE]: "Sorties de la queue",
      [RetailMetrics.INSIDE_QUEUE]: "À l'intérieur",
      [RetailMetrics.DWELL_QUEUE]: "Temps dans la queue",
      [RetailMetrics.ABANDON_RATE_QUEUE]: "Taux d'Abandon",
      [RetailMetrics.TEMPERATURE]: "Température",
      [RetailMetrics.HUMIDITY_LEVEL]: "Humidité",
      [RetailMetrics.CO2_LEVEL]: "CO2",
      [RetailMetrics.GROUP_PERCENTAGE]: "Pourcentage de groupes",
      [RetailMetrics.OCCUPANCY_STATUS]: "Statut",
      [RetailMetrics.DEVICE_STATUS]: "Statut",
      [RetailMetrics.WAITING_TIME]: "temps d'attente",
      [RetailMetrics.BINARY_OCCUPANCY]: "Taux d'Occupation au cours du temps",
      [RetailMetrics.CO2_LEVEL_SCORE]: "Score du Niveau de CO2",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION]: "",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ]: "",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ_PER_SQM]: "",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ]: "",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ_PER_SQM]: "",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_PER_SQM]: "",
      [RetailMetrics.COMFORT_SCORE]: "Confort",
      [RetailMetrics.ELECTRICITY_CONSUMPTION]: "Consommation Électrique",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ]: "Équivalent CO2 Consommation Électrique",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_PER_SQM]: "Équivalent CO2 Consommation Électrique par Mètre Carré",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ]: "Équivalent Énergétique Consommation Électrique",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_PER_SQM]: "Équivalent Énergétique Consommation Électrique par Mètre Carré",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_PER_SQM]: "Consommation Électrique par Mètre Carré",
      [RetailMetrics.ELECTRICITY_PRODUCTION]: "Production Électrique",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ]: "Équivalent CO2 Production Électrique",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_PER_SQM]: "Équivalent CO2 Production Électrique par Mètre Carré",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ]: "Équivalent Énergétique Production Électrique",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_PER_SQM]: "Équivalent Énergétique Production Électrique par Mètre Carré",
      [RetailMetrics.ELECTRICITY_PRODUCTION_PER_SQM]: "Production Électrique par Mètre Carré",
      [RetailMetrics.HUMIDITY_LEVEL_SCORE]: "Score du Niveau d'Humidité",
      [RetailMetrics.LUMINOSITY]: "Luminosité",
      [RetailMetrics.LUMINOSITY_SCORE]: "Score de Luminosité",
      [RetailMetrics.PM10_LEVEL]: "Niveau de PM10",
      [RetailMetrics.PM10_LEVEL_SCORE]: "Score de Niveau PM10",
      [RetailMetrics.PM2_5_LEVEL]: "Niveau PM2.5",
      [RetailMetrics.PM2_5_LEVEL_SCORE]: "Score de niveau PM2.5",
      [RetailMetrics.PRESSURE]: "Pression",
      [RetailMetrics.PRESSURE_SCORE]: "Score de Pression",
      [RetailMetrics.TEMPERATURE_SCORE]: "Score de Température",
      [RetailMetrics.TVOC]: "COV",
      [RetailMetrics.TVOC_SCORE]: "Score COV",
      [RetailMetrics.WATER_CONSUMPTION]: "Consommation d'Eau",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ]: "Équivalent CO2 Consommation d'Eau",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_PER_SQM]: "Équivalent CO2 Consommation d'Eau par Mètre Carré",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ]: "Équivalent Énergétique Consommation d'Eau",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_PER_SQM]: "Équivalent Énergétique Consommation d'Eau par Mètre Carré",
      [RetailMetrics.WATER_CONSUMPTION_PER_SQM]: "Consommation d'Eau par Mètre Carré",
      [RetailMetrics.LIMIT]: "limite",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_DAY]: "Consommation Électrique du Jour",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_INTERVAL]: "Consommation Électrique sur l'Intervalle",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_DAY]: "Équivalent CO2 Consommation Électrique du Jour",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_INTERVAL]: "Équivalent CO2 Consommation Électrique sur l'Intervalle",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_DAY]: "Équivalent Énergétique Consommation Électrique du Jour",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]: "Équivalent Énergétique Consommation Électrique sur l'Intervalle",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ON_DAY]: "Production Électrique du Jour",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_DAY]: "Équivalent CO2 Production Électrique du Jour",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_INTERVAL]: "Équivalent CO2 Production Électrique sur l'Intervalle",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_DAY]: "Équivalent Énergétique Production Électrique du Jour",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_INTERVAL]: "Équivalent Énergétique Production Électrique sur l'Intervalle",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ON_INTERVAL]: "Production Électrique sur l'Intervalle",
      [RetailMetrics.WATER_CONSUMPTION_ON_DAY]: "Consommation d'Eau du Jour",
    },
  },
  units: {
    legacy: {
      [WebAppMetrics.AFFLUENCE_IN]: "visitors",
      [WebAppMetrics.DWELL_TIME]: "s",
      [WebAppMetrics.INSIDE]: "personnes",
      [WebAppMetrics.LIMIT]: "",
      [WebAppMetrics.OCCUPANCY_RATE]: "%",
      [WebAppMetrics.WAITING_TIME]: "s",
    },
    vision: {
      [RetailMetrics.AFFLUENCE_IN]: "visiteurs",
      [RetailMetrics.AFFLUENCE_OUT]: "visiteurs",
      [RetailMetrics.CAPTURE_RATE]: "%",
      [RetailMetrics.POPULARITY]: "%",
      [RetailMetrics.OCCUPANCY]: "%",
      [RetailMetrics.ABANDON_RATE_QUEUE]: "%",
      [RetailMetrics.DWELL]: "s",
      [RetailMetrics.DWELL_QUEUE]: "s",
      [RetailMetrics.INSIDE]: "personnes",
      [RetailMetrics.AFFLUENCE_IN_QUEUE]: "",
      [RetailMetrics.AFFLUENCE_OUT_QUEUE]: "",
      [RetailMetrics.N_GROUPS_OUT]: "",
      [RetailMetrics.N_GROUPS_IN]: "",
      [RetailMetrics.INSIDE_QUEUE]: "",
      [RetailMetrics.GROUP_SIZE_OUT]: "",
      [RetailMetrics.GROUP_SIZE_IN]: "",
      [RetailMetrics.TEMPERATURE]: "°C",
      [RetailMetrics.HUMIDITY_LEVEL]: "%RH",
      [RetailMetrics.CO2_LEVEL]: "ppm",
      [RetailMetrics.DEVICE_STATUS]: " ",
      [RetailMetrics.GROUP_PERCENTAGE]: "%",
      [RetailMetrics.OCCUPANCY_STATUS]: " ",
      [RetailMetrics.DENSITY]: "p/m²",
      [RetailMetrics.REVERSE_DENSITY]: "m²/p",
      [RetailMetrics.WAITING_TIME]: "s",
      [RetailMetrics.LIMIT]: "",
      [RetailMetrics.BINARY_OCCUPANCY]: "%",
      [RetailMetrics.TVOC]: "μg/m3",
      [RetailMetrics.PM2_5_LEVEL]: "μg/m3",
      [RetailMetrics.PM10_LEVEL]: "μg/m3",
      [RetailMetrics.LUMINOSITY]: "lx",
      [RetailMetrics.PRESSURE]: "hPa",
      [RetailMetrics.COMFORT_SCORE]: "",
      [RetailMetrics.CO2_LEVEL_SCORE]: "",
      [RetailMetrics.HUMIDITY_LEVEL_SCORE]: "",
      [RetailMetrics.TEMPERATURE_SCORE]: "",
      [RetailMetrics.TVOC_SCORE]: "",
      [RetailMetrics.PM2_5_LEVEL_SCORE]: "",
      [RetailMetrics.PM10_LEVEL_SCORE]: "",
      [RetailMetrics.LUMINOSITY_SCORE]: "",
      [RetailMetrics.PRESSURE_SCORE]: "",
      [RetailMetrics.ELECTRICITY_PRODUCTION]: "kWh",
      [RetailMetrics.ELECTRICITY_CONSUMPTION]: "kWh",
      [RetailMetrics.ELECTRICITY_PRODUCTION_PER_SQM]: "kWh/m²",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ]: "MJ",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_PER_SQM]: "MJ/m²",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ]: "kgCO2",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_PER_SQM]: "kgCO2/m²",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_PER_SQM]: "kWh/m²",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ]: "MJ",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_PER_SQM]: "MJ/m²",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ]: "kgCO2",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_PER_SQM]: "kgCO2/m²",
      [RetailMetrics.WATER_CONSUMPTION]: "m³",
      [RetailMetrics.WATER_CONSUMPTION_PER_SQM]: "m³/m²",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ]: "MJ",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_PER_SQM]: "MJ/m²",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ]: "kgCO2",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_PER_SQM]: "kgCO2/m²",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION]: "m³",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_PER_SQM]: "m³/m²",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ]: "MJ",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ_PER_SQM]: "MJ/m²",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ]: "kgCO2",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ_PER_SQM]: "kgCO2/m²",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ON_DAY]: "kWh",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ON_INTERVAL]: "kWh",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_DAY]: "kWh",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_INTERVAL]: "kWh",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_DAY]: "MJ",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_INTERVAL]: "MJ",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_DAY]: "kgCO2",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_INTERVAL]: "kgCO2",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_DAY]: "MJ",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]: "MJ",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_DAY]: "kgCO2",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_INTERVAL]: "kgCO2",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_DAY]: "m³",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_INTERVAL]: "m³",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_DAY]: "MJ",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]: "MJ",
      [RetailMetrics.WATER_CONSUMPTION_ON_DAY]: "m³",
      [RetailMetrics.WATER_CONSUMPTION_ON_INTERVAL]: "m³",
    },
  },
};
