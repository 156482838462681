import { Count, ID, Maybe } from "@technis/shared";
import { off, onValue, ref } from "firebase/database";
import { useEffect, useRef, useState } from "react";
import { usePrevious } from ".";
import { firebaseDb } from "../index";

const getZoneCountUrl = (periodId: ID, zoneId: ID) => `${process.env.FIREBASE_DATABASE_ROOT}/counts/${periodId}/zone|${zoneId}`;

type CountFirebase = {
  person?: Maybe<Count>;
};

const DISCONNECT_CHECK_INTERVAL = 59999; // Every 59 seconds
// const THROTTLING_FLUX_TIMEOUT = 5000;
// const FLUX_CONTROL_MARGIN = 5;

/*
type FluxControl = {
  enabled: boolean;
  throttleStartedAt: Maybe<number>;
};
*/

const calculateNumOfAllowedEntries = (limit: number, count: Maybe<Count>) => limit - ((count?.in || 0) - (count?.out || 0));

export const useCount = (token: string | undefined, periodId: ID, topZoneId: ID, limit: number) => {
  const [count, setCount] = useState<Maybe<Count>>(null);
  // const [fluxControl, setFluxControl] = useState<FluxControl>({ enabled: false, throttleStartedAt: null });

  const isConnectedRef = useRef(false);
  const limitRef = useRef(limit);
  // const canUseFluxControlRef = useRef(false);
  const numOfAllowedEntriesRef = useRef(0);
  useEffect(() => {
    limitRef.current = limit;
    // canUseFluxControlRef.current = limit > FLUX_CONTROL_MARGIN;
    numOfAllowedEntriesRef.current = calculateNumOfAllowedEntries(limit, count);
    // setFluxControl(f => (!canUseFluxControlRef.current && f.enabled ? { ...f, enabled: false } : { ...f }));
  }, [limit]);

  const url = getZoneCountUrl(periodId || 0, topZoneId || 0);

  // Firebase listeners
  const saveCount = (nextCount: Maybe<Count>) => {
    if (nextCount) {
      const nextNumOfAllowedEntries = calculateNumOfAllowedEntries(limitRef.current, nextCount);
      numOfAllowedEntriesRef.current = nextNumOfAllowedEntries;
    }
    setCount(nextCount);
  };

  const startListening = () => {
    isConnectedRef.current = true;

    const countRef = ref(firebaseDb, url);

    onValue(
      countRef,
      (snapshot) => {
        const val: Maybe<CountFirebase> = snapshot.val();
        const nextCount = val?.person;
        saveCount(nextCount);
      },
      (err) => {
        console.log(err);
        isConnectedRef.current = false;
      },
    );
  };
  const stopListening = () => {
    off(ref(firebaseDb, url));
    isConnectedRef.current = false;
  };

  const prevToken = usePrevious(token);
  // Logic to keep firebase alive
  useEffect(() => {
    if (prevToken && isConnectedRef.current) {
      stopListening();
    }
    startListening();
  }, [token, periodId]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isConnectedRef.current) {
        startListening();
      }
    }, DISCONNECT_CHECK_INTERVAL);
    return () => {
      clearInterval(interval);
      if (isConnectedRef.current) {
        stopListening();
      }
    };
  }, []);

  return {
    count: count || undefined,
    isBiggerThanLimit: numOfAllowedEntriesRef.current <= 0,
    numOfAllowedEntries: numOfAllowedEntriesRef.current,
  };
};
