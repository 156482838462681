import * as H from "history";

import { Action, Dispatch } from "redux";
import { RouterParams } from "./router.reducer";

export enum RouterActions {
  UPDATE_ROUTER = "UPDATE_ROUTER",
  RESET_ROUTER = "RESET_ROUTER",
}

export type RouterAction = UpdateRouter | Action<RouterActions.RESET_ROUTER>;

interface UpdateRouter extends Action<RouterActions.UPDATE_ROUTER> {
  history: H.History;
  path?: string;
  params: RouterParams;
}

export const updateRouter = (history: H.History, path: string, params: RouterParams) => (dispatch: Dispatch<UpdateRouter>) =>
  dispatch({ type: RouterActions.UPDATE_ROUTER, history, path, params });
