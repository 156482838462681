import gql from "graphql-tag";
import { OccupancySite, OccupancySiteZone, Period, Zone } from "@technis/shared";

export type OccupancyZone = Zone & Pick<OccupancySiteZone, "kpis" | "customTitle"> & { installationId: number; organizationId: number };

export type OccupancyEvent = {
  zones: OccupancyZone[];
  limit: number;
  period: Period;
};

export type InitialDataResult = {
  initialOccupancyData: Pick<OccupancySite, "logo" | "faviconUrl" | "title" | "useMasonry" | "lang" | "backgroundUrl" | "technisLogoColor" | "technisLogo"> & {
    configs: Array<{
      sectionTitle: string;
      events: OccupancyEvent[];
    }>;
  };
};

export const initialData = gql`
  query initialOccupancyData {
    initialOccupancyData {
      logo {
        url
        position
      }
      technisLogo {
        color
        isHiden
      }
      backgroundUrl
      technisLogoColor
      faviconUrl
      title
      useMasonry
      lang
      configs {
        sectionTitle
        events {
          zones {
            id
            name
            installationId
            organizationId
            customTitle
            kpis {
              type
              limit
              dataSource
              description
              customSubtitle
              colorimetry {
                values
                color
                text
              }
            }
          }
          limit
          period {
            id
            dateBegin
            dateEnd
          }
        }
      }
    }
  }
`;
