import { RetailMetrics, WebAppMetrics } from "@technis/shared";

export const en = {
  notFound: "Page not found",
  poweredBy: "powered by",
  persons: "persons",
  free: "Free",
  occupied: "Occupied",
  scores: {
    COMFORT_SCORE: {
      "1": "Bad",
      "2": "Poor",
      "3": "Average",
      "4": "God",
      "5": "Excellent",
    },
    GENERAL_SCORE: {
      "1": "Low",
      "2": "Average",
      "3": "Good",
    },
  },
  descriptions: {
    legacy: {
      [WebAppMetrics.AFFLUENCE_IN]: "Number of visitors",
      [WebAppMetrics.DWELL_TIME]: "Estimated waiting time",
      [WebAppMetrics.INSIDE]: "Number of people inside",
      [WebAppMetrics.LIMIT]: "Limit for the number of people inside the zone",
      [WebAppMetrics.OCCUPANCY_RATE]: "Occupancy rate",
      [WebAppMetrics.WAITING_TIME]: "Estimated waiting time",
    },
    vision: {
      [RetailMetrics.DWELL]: "Average Dwell Time",
      [RetailMetrics.CAPTURE_RATE]: "Capture Rate",
      [RetailMetrics.POPULARITY]: "Popularity",
      [RetailMetrics.AFFLUENCE_IN]: "Number of visitors",
      [RetailMetrics.AFFLUENCE_OUT]: "Exits",
      [RetailMetrics.INSIDE]: "Number of visitors inside",
      [RetailMetrics.GROUP_SIZE_IN]: "Group size",
      [RetailMetrics.GROUP_SIZE_OUT]: "Group size",
      [RetailMetrics.N_GROUPS_IN]: "Number of groups",
      [RetailMetrics.N_GROUPS_OUT]: "Number of groups",
      [RetailMetrics.OCCUPANCY]: "Occupancy",
      [RetailMetrics.AFFLUENCE_IN_QUEUE]: "Queue entry",
      [RetailMetrics.AFFLUENCE_OUT_QUEUE]: "Queue exits",
      [RetailMetrics.INSIDE_QUEUE]: "Inside",
      [RetailMetrics.DWELL_QUEUE]: "Time in queue",
      [RetailMetrics.ABANDON_RATE_QUEUE]: "Abandon rate",
      [RetailMetrics.TEMPERATURE]: "Temperature",
      [RetailMetrics.HUMIDITY_LEVEL]: "Humidity",
      [RetailMetrics.CO2_LEVEL]: "CO2",
      [RetailMetrics.GROUP_PERCENTAGE]: "Group Percentage",
      [RetailMetrics.OCCUPANCY_STATUS]: "Status",
      [RetailMetrics.DEVICE_STATUS]: "Status",
      [RetailMetrics.WAITING_TIME]: "waiting time",
      [RetailMetrics.BINARY_OCCUPANCY]: "Occupancy Rate over Time",
      [RetailMetrics.CO2_LEVEL_SCORE]: "CO2 Level Score",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION]: "Fuel Consumption",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ]: "CO2 Equivalent of Fuel Consumption",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ_PER_SQM]: "CO2 Equivalent of Fuel Consumption per Square Meter",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ]: "Energy Equivalent of Fuel Consumption",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ_PER_SQM]: "Energy Equivalent of Fuel Consumption per Square Meter",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_PER_SQM]: "Fuel Consumption per Square Meter",
      [RetailMetrics.COMFORT_SCORE]: "Comfort",
      [RetailMetrics.ELECTRICITY_CONSUMPTION]: "Electricity Consumption",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ]: "CO2 Equivalent of Electricity Consumption",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_PER_SQM]: "CO2 Equivalent of Electricity Consumption per Square Meter",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ]: "Energy Equivalent of Electricity Consumption",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_PER_SQM]: "Energy Equivalent of Electricity Consumption per Square Meter",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_PER_SQM]: "Electricity Consumption per Square Meter",
      [RetailMetrics.ELECTRICITY_PRODUCTION]: "Electricity Production",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ]: "CO2 Equivalent of Electricity Production",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_PER_SQM]: "CO2 Equivalent of Electricity Production per Square Meter",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ]: "Energy Equivalent of Electricity Production",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_PER_SQM]: "Energy Equivalent of Electricity Production per Square Meter",
      [RetailMetrics.ELECTRICITY_PRODUCTION_PER_SQM]: "Electricity Production per Square Meter",
      [RetailMetrics.HUMIDITY_LEVEL_SCORE]: "Humidity Level Score",
      [RetailMetrics.LUMINOSITY]: "Luminosity",
      [RetailMetrics.LUMINOSITY_SCORE]: "Luminosity Score",
      [RetailMetrics.PM10_LEVEL]: "PM10 Level",
      [RetailMetrics.PM10_LEVEL_SCORE]: "PM10 Level Score",
      [RetailMetrics.PM2_5_LEVEL]: "PM2.5 Level",
      [RetailMetrics.PM2_5_LEVEL_SCORE]: "PM2.5 Level Score",
      [RetailMetrics.PRESSURE]: "Pressure",
      [RetailMetrics.PRESSURE_SCORE]: "Pressure Score",
      [RetailMetrics.TEMPERATURE_SCORE]: "Temperature Score",
      [RetailMetrics.TVOC]: "TVOC",
      [RetailMetrics.TVOC_SCORE]: "TVOC Score",
      [RetailMetrics.WATER_CONSUMPTION]: "Water Consumption",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ]: "CO2 Equivalent of Water Consumption",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_PER_SQM]: "CO2 Equivalent of Water Consumption per Square Meter",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ]: "Energy Equivalent of Water Consumption",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_PER_SQM]: "Energy Equivalent of Water Consumption per Square Meter",
      [RetailMetrics.WATER_CONSUMPTION_PER_SQM]: "Water Consumption per Square Meter",
      [RetailMetrics.LIMIT]: "limit",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_DAY]: "Electricity Consumption on Day",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_INTERVAL]: "Electricity Consumption on Interval",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_DAY]: "CO2 Equivalent of Electricity Consumption on Day",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_INTERVAL]: "CO2 Equivalent of Electricity Consumption on Interval",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_DAY]: "Energy Equivalent of Electricity Consumption on Day",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]: "Energy Equivalent of Electricity Consumption on Interval",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ON_DAY]: "Electricity Production on Day",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_DAY]: "CO2 Equivalent of Electricity Production on Day",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_INTERVAL]: "CO2 Equivalent of Electricity Production on Interval",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_DAY]: "Energy Equivalent of Electricity Production on Day",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_INTERVAL]: "Energy Equivalent of Electricity Production on Interval",
      [RetailMetrics.WATER_CONSUMPTION_ON_DAY]: "Water Consumption on Day",
    },
  },
  units: {
    legacy: {
      [WebAppMetrics.AFFLUENCE_IN]: "visitors",
      [WebAppMetrics.DWELL_TIME]: "s",
      [WebAppMetrics.INSIDE]: "persons",
      [WebAppMetrics.LIMIT]: "",
      [WebAppMetrics.OCCUPANCY_RATE]: "%",
      [WebAppMetrics.WAITING_TIME]: "s",
    },
    vision: {
      [RetailMetrics.AFFLUENCE_IN]: "visitors",
      [RetailMetrics.AFFLUENCE_OUT]: "visitors",
      [RetailMetrics.CAPTURE_RATE]: "%",
      [RetailMetrics.POPULARITY]: "%",
      [RetailMetrics.OCCUPANCY]: "%",
      [RetailMetrics.ABANDON_RATE_QUEUE]: "%",
      [RetailMetrics.DWELL]: "s",
      [RetailMetrics.DWELL_QUEUE]: "s",
      [RetailMetrics.INSIDE]: "persons",
      [RetailMetrics.AFFLUENCE_IN_QUEUE]: "",
      [RetailMetrics.AFFLUENCE_OUT_QUEUE]: "",
      [RetailMetrics.N_GROUPS_OUT]: "",
      [RetailMetrics.N_GROUPS_IN]: "",
      [RetailMetrics.INSIDE_QUEUE]: "",
      [RetailMetrics.GROUP_SIZE_OUT]: "",
      [RetailMetrics.GROUP_SIZE_IN]: "",
      [RetailMetrics.TEMPERATURE]: "°C",
      [RetailMetrics.HUMIDITY_LEVEL]: "%RH",
      [RetailMetrics.CO2_LEVEL]: "ppm",
      [RetailMetrics.DEVICE_STATUS]: "",
      [RetailMetrics.GROUP_PERCENTAGE]: "%",
      [RetailMetrics.OCCUPANCY_STATUS]: "",
      [RetailMetrics.DENSITY]: "p/m²",
      [RetailMetrics.REVERSE_DENSITY]: "m²/p",
      [RetailMetrics.WAITING_TIME]: "s",
      [RetailMetrics.LIMIT]: "",
      [RetailMetrics.BINARY_OCCUPANCY]: "%",
      [RetailMetrics.TVOC]: "μg/m3",
      [RetailMetrics.PM2_5_LEVEL]: "μg/m3",
      [RetailMetrics.PM10_LEVEL]: "μg/m3",
      [RetailMetrics.LUMINOSITY]: "lx",
      [RetailMetrics.PRESSURE]: "hPa",
      [RetailMetrics.COMFORT_SCORE]: "",
      [RetailMetrics.CO2_LEVEL_SCORE]: "",
      [RetailMetrics.HUMIDITY_LEVEL_SCORE]: "",
      [RetailMetrics.TEMPERATURE_SCORE]: "",
      [RetailMetrics.TVOC_SCORE]: "",
      [RetailMetrics.PM2_5_LEVEL_SCORE]: "",
      [RetailMetrics.PM10_LEVEL_SCORE]: "",
      [RetailMetrics.LUMINOSITY_SCORE]: "",
      [RetailMetrics.PRESSURE_SCORE]: "",
      [RetailMetrics.ELECTRICITY_PRODUCTION]: "kWh",
      [RetailMetrics.ELECTRICITY_CONSUMPTION]: "kWh",
      [RetailMetrics.ELECTRICITY_PRODUCTION_PER_SQM]: "kWh/m²",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ]: "MJ",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_PER_SQM]: "MJ/m²",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ]: "kgCO2",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_PER_SQM]: "kgCO2/m²",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_PER_SQM]: "kWh/m²",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ]: "MJ",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_PER_SQM]: "MJ/m²",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ]: "kgCO2",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_PER_SQM]: "kgCO2/m²",
      [RetailMetrics.WATER_CONSUMPTION]: "m³",
      [RetailMetrics.WATER_CONSUMPTION_PER_SQM]: "m³/m²",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ]: "MJ",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_PER_SQM]: "MJ/m²",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ]: "kgCO2",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_PER_SQM]: "kgCO2/m²",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION]: "m³",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_PER_SQM]: "m³/m²",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ]: "MJ",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ_PER_SQM]: "MJ/m²",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ]: "kgCO2",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ_PER_SQM]: "kgCO2/m²",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ON_DAY]: "kWh",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ON_INTERVAL]: "kWh",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_DAY]: "kWh",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_INTERVAL]: "kWh",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_DAY]: "MJ",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_INTERVAL]: "MJ",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_DAY]: "kgCO2",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_INTERVAL]: "kgCO2",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_DAY]: "MJ",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]: "MJ",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_DAY]: "kgCO2",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_INTERVAL]: "kgCO2",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_DAY]: "m³",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_INTERVAL]: "m³",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_DAY]: "MJ",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]: "MJ",
      [RetailMetrics.WATER_CONSUMPTION_ON_DAY]: "m³",
      [RetailMetrics.WATER_CONSUMPTION_ON_INTERVAL]: "m³",
    },
  },
};
