import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import moment from "moment";
import { off, onValue, ref } from "firebase/database";
import { firebaseDb } from "../../../..";

const TODAY = moment().format("YYYY-MM-DD");
const RESTAURANT_A_ZONE = 2678;

const GET_DATA = gql`
  query {
    inside: getKPIs(zoneId: ${RESTAURANT_A_ZONE}, kpiName: INSIDE, granularity: HOUR, dateBegin: "${TODAY}", dateEnd: "${TODAY}")
    
  }
`;

function usePrevious(value: string | undefined): string | undefined {
  const ref = useRef<string | undefined>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

type ResultData = { inside: { values: { value: number; timestamp: string }[] }; current: number };

const publicClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: "https://api-application.technis.com/api/v2/graphql",
  }),
});

export const useInsideKPI = (token: string | undefined) => {
  const [insideKPIs, setInsideKPIs] = useState<(number | null)[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [occupancy, setOccupancy] = useState<number>();

  const { data, startPolling, stopPolling, error } = useQuery<ResultData>(GET_DATA, {
    client: publicClient,
    fetchPolicy: "network-only",
    pollInterval: 600000,
    context: {
      headers: {
        Authorization:
          // eslint-disable-next-line max-len
          "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiIzUEYwcUVSdUxRUEk5Q1NwYVgwVW1hb2IwVHUyIiwiaXNzIjoiZmlyZWJhc2UtYWRtaW5zZGstZjhkYzhAdGVjaG5pcy1jb3VudGluZy5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInN1YiI6ImZpcmViYXNlLWFkbWluc2RrLWY4ZGM4QHRlY2huaXMtY291bnRpbmcuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTcxMDQxOTYwNCwiY2xhaW1zIjp7ImlzQXBpS2V5Ijp0cnVlLCJ0b2tlblR5cGUiOiJwdWJsaWMifX0.BE5g1gGNme8_Yx82QwfIBWbJsrSSNLyyjwiABMkV8Q7SxEpQZCsjMQbIiW7r5JaxXMcLVIdN2SGBCRUgGudRSWkXE-kcVxvqJjHCX62xtqvnkIMJHnoZDbzbInCi6_w4DlZwFooeggCob50BMIUIdkA_YKMDyl4ozZWg6aDb8zWMbrbVn2xGAWcVw2hE31ifQb2QDWtb4DJcxRtryZVqTIqakMACH7ULu5b1Qka7t3XvM_ZyYNQrHZCYZ_3HIq0wkmekNTwNuMJoSrLMUx3jgASM3IK2JHhBwr6owI8cQy1gJRar1TQINiDpjh-RZkB5gLRmEE_WT35OguLVT92u_Q",
      },
    },
    onError: (error) => console.error("GraphQL error:", error),
  });

  useEffect(() => {
    // every 30 minutes
    startPolling(1800000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  useEffect(() => {
    // When data is updated, update the state
    if (data && data.inside) {
      const currentHourIndex = moment().hour() - 1;
      const nullArray = new Array(24).fill(null);
      const results = nullArray.map((_, index) => (index <= currentHourIndex ? data.inside.values[index]?.value ?? null : null));
      results[currentHourIndex] = data.current ?? results[currentHourIndex];
      setInsideKPIs(results);

      const resCategories = data.inside.values.map((item) => moment(item.timestamp).hour().toString());
      setCategories(resCategories);
    }
  }, [data]);

  const isConnectedRef = useRef(false);

  const url = "/production/liveKPIs/711/1629/zones/2678";

  const startListening = () => {
    isConnectedRef.current = true;
    const liveDataRef = ref(firebaseDb, url);

    onValue(
      liveDataRef,
      (snapshot) => {
        const value = snapshot.val();

        setOccupancy(value.INSIDE);
      },
      (error) => {
        console.error(error);
        isConnectedRef.current = false;
      },
    );
  };

  const stopListening = () => {
    off(ref(firebaseDb, url));
    isConnectedRef.current = false;
  };

  const prevToken = usePrevious(token);

  useEffect(() => {
    if (prevToken && isConnectedRef.current) {
      stopListening();
      startListening();
    } else if (!isConnectedRef.current) {
      startListening();
    }

    return () => {
      if (isConnectedRef.current) {
        stopListening();
      }
    };
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isConnectedRef.current) {
        startListening();
      }
    }, 10000);

    return () => {
      clearInterval(interval);
      if (isConnectedRef.current) {
        stopListening();
      }
    };
  }, []);

  return {
    seriesData: insideKPIs,
    categories,
    occupancy,
  };
};
