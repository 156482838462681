import React, { FC, PropsWithChildren } from "react";

import { useSearchParams } from "react-router-dom";

export const ZoomProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [searchParams] = useSearchParams();

  const zoomParam = searchParams.get("zoom") ?? "100";

  return <div style={{ zoom: `${zoomParam}%` }}>{children}</div>;
};
