import { Action } from "redux";
import { store } from "../store";
import { loginToApi, LoginToApiResult } from "../../graphql/auth.gql";
import { ApolloClient } from "../../graphql";
import { getAuth, signInWithCustomToken } from "firebase/auth";

export enum AuthActions {
  AUTH_SAVE_TOKEN = "AUTH_SAVE_TOKEN",
}
interface AuthSaveTokenAction extends Action<AuthActions.AUTH_SAVE_TOKEN> {
  token?: string;
}

export type AuthAction = AuthSaveTokenAction;

export const saveToken = async (token: string) => {
  store.dispatch({ type: AuthActions.AUTH_SAVE_TOKEN, token });
};

export const login = (subdomain: string) => async () => {
  const token = await ApolloClient.query<LoginToApiResult>(loginToApi, { subdomain }).then(({ data: { loginOccupancy: token } }) => token);
  await signInWithCustomToken(getAuth(), token);
  await saveToken(token);
};
