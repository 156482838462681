import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import { NotFound } from "../NotFound";
import { Home } from "../Home";
import { ErrorBoundary } from "../common/ErrorBoundary";

export const MAIN_ROUTE_PATHS = {
  ROOT: "/",
  NOT_FOUND: "*",
};
export class MainRoutes extends Component {
  public render() {
    return (
      <Routes>
        <Route
          path={MAIN_ROUTE_PATHS.ROOT}
          element={
            <ErrorBoundary>
              <Home />
            </ErrorBoundary>
          }
        />
        <Route
          path={MAIN_ROUTE_PATHS.NOT_FOUND}
          element={
            <ErrorBoundary>
              <NotFound />
            </ErrorBoundary>
          }
        />
      </Routes>
    );
  }
}
