// import { ID, Maybe, WebAppMetrics, isDefined } from "@technis/shared";
import { useEffect, useRef, useState } from "react";
import { get, off, onValue, ref, set } from "firebase/database";
import { usePrevious } from "@technis/ui";
import { firebaseDb } from "../../../../..";
import { DISCONNECT_CHECK_INTERVAL } from "../../../../../common/constants";

const url = "/production/liveKPIs/706/1514/zones";

const LEFT_ZONE_ID = 2401;
const MIDDLE_ZONE_ID = 2402;
const RIGHT_ZONE_ID = 2400;

interface Result {
  leftZoneOccupancy: number;
  middleZoneOccupancy: number;
  rightZoneOccupancy: number;
}

export const useInside = (token: string | undefined): Result => {
  const [value, setValue] = useState<Result>({
    leftZoneOccupancy: 0,
    middleZoneOccupancy: 0,
    rightZoneOccupancy: 0,
  });

  const isConnectedRef = useRef(false);

  const startListening = () => {
    isConnectedRef.current = true;
    const countRef = ref(firebaseDb, url);

    onValue(
      countRef,
      (snapshot) => {
        const dbValue = snapshot.val();

        const newValue: Result = {
          leftZoneOccupancy: dbValue[LEFT_ZONE_ID].OCCUPANCY ?? 0,
          middleZoneOccupancy: dbValue[MIDDLE_ZONE_ID].OCCUPANCY ?? 0,
          rightZoneOccupancy: dbValue[RIGHT_ZONE_ID].OCCUPANCY ?? 0,
        };

        setValue(newValue);
      },
      (err) => {
        console.log(err);
        isConnectedRef.current = false;
      },
    );
  };

  const stopListening = () => {
    off(ref(firebaseDb, url));
    isConnectedRef.current = false;
  };

  const prevToken = usePrevious(token);

  // Logic to keep firebase alive
  useEffect(() => {
    if (prevToken && isConnectedRef.current) {
      stopListening();
    }
    startListening();
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isConnectedRef.current) {
        startListening();
      }
    }, DISCONNECT_CHECK_INTERVAL);
    return () => {
      clearInterval(interval);
      if (isConnectedRef.current) {
        stopListening();
      }
    };
  }, []);

  return value;
};

export const incrementConversionByOne = async () => {
  const reference = ref(firebaseDb, url);

  const { conversionCount } = (await get(reference)).val();

  await set(reference, { conversionCount: conversionCount + 1 });
};

export const decrementConversionByOne = async () => {
  const reference = ref(firebaseDb, url);

  const { conversionCount } = (await get(reference)).val();

  await set(reference, { conversionCount: conversionCount - 1 });
};

export const resetConversion = async () => {
  const reference = ref(firebaseDb, url);

  await set(reference, { conversionCount: 0 });
};
