import { Button, ComponentSize, Heading } from "@technis/ui";
import React, { Component, ErrorInfo, ReactNode } from "react";
import { ErrorComponent } from "./ErrorComponent";

interface OwnProps {
  children?: ReactNode;
  error?: Error;
}

interface State {
  hasError: boolean;
  errorDetails: string;
  countdown: number;
}

type Props = OwnProps;

export class ErrorBoundary extends Component<Props, State> {
  private errorTimer: NodeJS.Timer | null = null;

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, errorDetails: "", countdown: 60 };
  }

  public static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      errorDetails: `
      Error name: ${error.name}.
      Error message: ${error.message}.
      Error info: ${errorInfo.componentStack}.
    `,
    });
  }

  reload = (): void => window.location.reload();

  render(): ReactNode {
    const { hasError, errorDetails } = this.state;

    if (hasError) {
      <ErrorComponent errorDetails={errorDetails} />;
    }

    return this.props.children;
  }
}
