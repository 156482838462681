import React, { FC } from "react";

import { Layer0DaliVipOccupancy } from "./Layer0";
import { Layer1DaliVipOccupancy } from "./Layer1";
import { Layer2DaliVipOccupancy } from "./Layer2";
import { LegendDaliVipOccupancy } from "./Legend";
import { TitleDaliVipOccupancy } from "./Title";

interface ZoneDatas {
  occupancy: number;
}

interface Props {
  leftZone: ZoneDatas;
  middleZone: ZoneDatas;
  rightZone: ZoneDatas;
}

const COLORS = ["#347D64", "#8E5708", "#75151F"];

const getColorForZone = (occupancy: number): string => {
  if (occupancy <= 40) return COLORS[0];
  if (occupancy >= 90) return COLORS[2];

  return COLORS[1];
};

const MAP_RATIO = 1144 / 444;

export const MapDaliVipOccupancy: FC<Props> = ({ leftZone, middleZone, rightZone }) => (
  <div style={{ position: "relative", display: "flex", flexDirection: "column", width: "100%", maxHeight: "100vh", maxWidth: "100vw", boxSizing: "border-box", padding: "20px" }}>
    <TitleDaliVipOccupancy />
    <div style={{ position: "relative", aspectRatio: MAP_RATIO, flex: 1, boxSizing: "border-box" }}>
      <Layer0DaliVipOccupancy />
      <Layer1DaliVipOccupancy
        leftZoneColor={getColorForZone(leftZone.occupancy)}
        middleZoneColor={getColorForZone(middleZone.occupancy)}
        rightZoneColor={getColorForZone(rightZone.occupancy)}
      />
      <Layer2DaliVipOccupancy />
    </div>
    <LegendDaliVipOccupancy />
    <img
      // eslint-disable-next-line max-len
      src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Ff228d4e96ae72b5096dc80faffb81414.cdn.bubble.io%2Ff1677508233209x583146886436787500%2FGroup.png?w=128&h=29&auto=compress&dpr=1&fit=max"
      alt="Logo iberia"
      width={120}
      style={{
        position: "fixed",
        right: 20,
        top: 20,
      }}
    />
  </div>
);
