import React from "react";
import { useVisionData } from "../../../../hooks/useVisionData";
import { RetailMetrics, roundByMetric } from "@technis/shared";
import moment from "moment";
import { DefaultSiteInfo } from "./types";

export const TotalKpis = ({ installationId, organizationId, zoneId }: DefaultSiteInfo & { zoneId: number }) => {
  const inside = useVisionData({ installationId, organizationId, zoneId, metric: RetailMetrics.INSIDE });
  const dwellRaw = useVisionData({ installationId, organizationId, zoneId, metric: RetailMetrics.DWELL });
  const occupancy = ((inside || 0) / 45) * 100;

  const duration = moment.duration(dwellRaw, "seconds");
  const hours = duration.hours();
  const minutes = duration.minutes();

  return (
    <div className="investor-page__left-kpis">
      <div className="investor-page__left-kpis__item investor-page__left-kpis__item--text">Total</div>
      <div className="investor-page__left-kpis__item investor-page__left-kpis__item--card">
        <span className="value">{inside}</span>
        <span className="metric">Visitors inside</span>
      </div>
      <div className="investor-page__left-kpis__item investor-page__left-kpis__item--card">
        <span className="value">{roundByMetric(RetailMetrics.OCCUPANCY, occupancy || 0)}</span>
        <span className="unit">%</span>
        <span className="metric">Occupancy rate</span>
      </div>
      <div className="investor-page__left-kpis__item investor-page__left-kpis__item--card">
        {!!hours && (
          <>
            <span className="value">{hours}</span>
            <span className="unit">h</span>
          </>
        )}
        {!!minutes && (
          <>
            <span className="value">{minutes}</span>
            <span className="unit">min</span>
          </>
        )}
        {!hours && !minutes && (
          <>
            <span className="value">-</span>
            <span className="unit">min</span>
          </>
        )}
        <span className="metric">Dwell time</span>
      </div>
    </div>
  );
};
