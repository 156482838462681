import React, { FC } from "react";

export interface Props {
  count: number;
  onAdd: () => void;
  onRemove: () => void;
  onReset: () => void;
}

export const CounterCES: FC<Props> = ({ count, onAdd, onReset, onRemove }) => {
  const handleReset = async () => {
    const result = await confirm("Are you sure you want to resert the counter to 0 ? This action cannot be reverted !");
    if (result === true) {
      onReset();
    }
  };
  return (
    <div className="ces2025__counter" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
      <span className="ces2025__counter__text1">Contacts</span>
      <span className="ces2025__counter__text2">{count}</span>
      <span className="ces2025__counter__text3">added on CRM count</span>
      <div className="ces2025__buttons">
        <button className="ces2025__button ces2025__button--primary" onClick={onAdd}>
          Add
        </button>
        <button className="ces2025__button ces2025__button--secondary" onClick={onRemove}>
          Remove
        </button>
        <button className="ces2025__button ces2025__button--text" onClick={handleReset}>
          Reset to zero
        </button>
      </div>
    </div>
  );
};
