// import { ID, Maybe, WebAppMetrics, isDefined } from "@technis/shared";
import { useEffect, useRef, useState } from "react";
import { get, off, onValue, ref, set } from "firebase/database";
import { usePrevious } from "@technis/ui";
import { firebaseDb } from "../../../..";
import { DISCONNECT_CHECK_INTERVAL } from "../../../../common/constants";

const url = "/production/liveKPIs/706/2021/zones";

const VINOTECA_ZONE_ID = 3907;
const BUFE_SOUTH_ZONE_ID = 3998;
const SOUTH_ZONE_ID = 3905;
const CONSIGNA_ZONE_ID = 3904;
const ENTERTAIMENT_ZONE_ID = 3908;
const NORTH_ZONE_ID = 3909;
const BUFE_NORTH_ZONE_ID = 3985;
const RESTAURANT_ZONE = 3912;

interface Result {
  vinotecaZoneOccupancy: number;
  bufeSouthZoneOccupancy: number;
  southZoneOccupancy: number;
  consignaZoneOccupancy: number;
  entertaimentZoneOccupancy: number;
  northZoneOccupancy: number;
  bufeNorthZoneOccupancy: number;
  restaurantZoneOccupancy: number;
}

export const useInsideVelazquez = (token: string | undefined): Result => {
  const [value, setValue] = useState<Result>({
    vinotecaZoneOccupancy: 0,
    bufeSouthZoneOccupancy: 0,
    southZoneOccupancy: 0,
    consignaZoneOccupancy: 0,
    entertaimentZoneOccupancy: 0,
    northZoneOccupancy: 0,
    bufeNorthZoneOccupancy: 0,
    restaurantZoneOccupancy: 0,
  });

  const isConnectedRef = useRef(false);

  const startListening = () => {
    isConnectedRef.current = true;
    const countRef = ref(firebaseDb, url);

    onValue(
      countRef,
      (snapshot) => {
        const dbValue = snapshot.val();

        const newValue: Result = {
          vinotecaZoneOccupancy: dbValue[VINOTECA_ZONE_ID]["INSIDE"] ?? 0,
          bufeSouthZoneOccupancy: dbValue[BUFE_SOUTH_ZONE_ID]["INSIDE"] ?? 0,
          southZoneOccupancy: dbValue[SOUTH_ZONE_ID]["INSIDE"] ?? 0,
          consignaZoneOccupancy: dbValue[CONSIGNA_ZONE_ID]["INSIDE"] ?? 0,
          entertaimentZoneOccupancy: dbValue[ENTERTAIMENT_ZONE_ID]["INSIDE"] ?? 0,
          northZoneOccupancy: dbValue[NORTH_ZONE_ID]["INSIDE"] ?? 0,
          bufeNorthZoneOccupancy: dbValue[BUFE_NORTH_ZONE_ID]["INSIDE"] ?? 0,
          restaurantZoneOccupancy: dbValue[RESTAURANT_ZONE]["INSIDE"] ?? 0,
        };

        setValue(newValue);
      },
      (err) => {
        console.log(err);
        isConnectedRef.current = false;
      },
    );
  };

  const stopListening = () => {
    off(ref(firebaseDb, url));
    isConnectedRef.current = false;
  };

  const prevToken = usePrevious(token);

  // Logic to keep firebase alive
  useEffect(() => {
    if (prevToken && isConnectedRef.current) {
      stopListening();
    }
    startListening();
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isConnectedRef.current) {
        startListening();
      }
    }, DISCONNECT_CHECK_INTERVAL);
    return () => {
      clearInterval(interval);
      if (isConnectedRef.current) {
        stopListening();
      }
    };
  }, []);

  return value;
};

export const incrementConversionByOne = async () => {
  const reference = ref(firebaseDb, url);

  const { conversionCount } = (await get(reference)).val();

  await set(reference, { conversionCount: conversionCount + 1 });
};

export const decrementConversionByOne = async () => {
  const reference = ref(firebaseDb, url);

  const { conversionCount } = (await get(reference)).val();

  await set(reference, { conversionCount: conversionCount - 1 });
};

export const resetConversion = async () => {
  const reference = ref(firebaseDb, url);

  await set(reference, { conversionCount: 0 });
};
