import { ApolloQueryResult, MutationOptions, OperationVariables, QueryOptions, SubscriptionOptions } from "apollo-client";
import { FetchResult } from "apollo-link";
import { DocumentNode } from "graphql";

import { apollo } from "../api/apollo";
import { removeUndefined } from "../utils/utils";

export const ApolloClient = {
  refresh: (query: DocumentNode, variables: OperationVariables = {}, options?: Omit<QueryOptions<OperationVariables>, "query" | "variables" | "fetchPolicy">) =>
    apollo.client.query({
      query,
      variables: removeUndefined(variables),
      ...(options || {}),
      fetchPolicy: "network-only",
    }),
  query: <T>(query: DocumentNode, variables: OperationVariables = {}, options?: Omit<QueryOptions<OperationVariables>, "query" | "variables">) =>
    apollo.client.query<T>({ query, variables: removeUndefined(variables), ...(options || {}) }),
  mutate: (mutation: DocumentNode, variables: OperationVariables = {}, options?: Omit<MutationOptions<OperationVariables>, "variables">) =>
    apollo.client.mutate({ mutation, variables: removeUndefined(variables), ...(options || {}) }),
  subscribe: <T>(query: DocumentNode, variables: OperationVariables = {}, options?: Omit<SubscriptionOptions<OperationVariables>, "variables">) =>
    apollo.client.subscribe({ query, variables: removeUndefined(variables), ...(options || {}) }),
};

type Query<T> = ApolloQueryResult<T>;
type Mutation<T> = FetchResult<Partial<T>>;
