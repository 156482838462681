import React, { FC } from "react";

interface LabelsProps {}

const numArr = [1, 2, 3, 4, 5];
const stringArr = ["Meeting room", "Kitchen / bar", "Ping pong table", "Office", "Couch"];

const labelList = numArr.map((num, index) => (
  <div className="label-container" key={num}>
    <div className="number-circle">{num.toString().padStart(2, "0")}</div>
    <div className="pingpong-text">{stringArr[index]}</div>
  </div>
));

export const Labels: FC<LabelsProps> = () => {
  const mu = 1;
  return <div className="label-column-container">{labelList}</div>;
};
