import React, { useEffect, useState } from "react";
import { Button, ComponentSize, Heading } from "@technis/ui";

type Props = {
  showDetails?: boolean;
  errorDetails: string;
};

const useCountdown = (initialCountdown = 60) => {
  const [countdown, setCountdown] = useState(initialCountdown);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return countdown;
};

export const ErrorComponent = ({ showDetails = window.location.search.includes("debug"), errorDetails }: Props) => {
  const countdown = useCountdown(60);
  const subject = encodeURIComponent(`Technis webapp error report: ${window.location.href}`);
  const body = encodeURIComponent(errorDetails);

  const reloadText = `Reload (${countdown}s)`;

  const onReload = () => {
    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    if (countdown === 0) {
      onReload();
    }
  }, [countdown]);

  return (
    <div className="errorContainer">
      <Heading size={ComponentSize.LARGE}>Oops! Something went wrong!</Heading>

      <p>
        If the issue is permanent, please <a href={`mailto:support@technis.com?subject=${subject}&body=${body}`}>contact our support team!</a>
      </p>

      <div className="errorBoundary--buttons">
        <Button size={ComponentSize.MEDIUM} onClick={onReload}>
          {reloadText}
        </Button>
      </div>

      {showDetails && <code>{errorDetails}</code>}
    </div>
  );
};
