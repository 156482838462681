import React, { FC } from "react";
import { KeyIndicatorV2 } from "@technis/ui";

interface PorteData {
  id: number;
  affluenceIn: number;
  affluenceOut: number;
  name: string;
}

interface KpiColumnsProps {
  passageData: PorteData[];
}

export const KpiColumns: FC<KpiColumnsProps> = ({ passageData }) => {
  const firstColumnIds = ["Porte 1", "Porte 5", "Porte 8", "Porte 12"];
  const secondColumnIds = ["Porte 2", "Porte 7", "Porte 9", "Porte 13"];

  const firstColumnData = passageData.filter((porte) => firstColumnIds.includes(porte.name));
  const secondColumnData = passageData.filter((porte) => secondColumnIds.includes(porte.name));

  const renderPorteContainer = (porte: PorteData) => (
    <div className="passage-container" key={porte.id}>
      <div className="title-container">
        <span className="title-text">{porte.name}</span>
      </div>
      <div className="kpi-column-container">
        <KeyIndicatorV2
          className="KeyIndicatorV2__wrapper"
          defaultIcon={{
            backgroundColor: "#31AE72",
            iconColor: "#FFFFFF",
            iconName: "affluence-in_custom",
          }}
          description="Nombre de visites"
          valueObject={{
            valueAsString: `${porte.affluenceIn}`,
          }}
        />
        <KeyIndicatorV2
          className="KeyIndicatorV2__wrapper"
          defaultIcon={{
            backgroundColor: "#D85252",
            iconColor: "#FFFFFF",
            iconName: "affluence-out_custom",
          }}
          description="Nombre de sorties"
          valueObject={{
            valueAsString: `${porte.affluenceOut}`,
          }}
        />
      </div>
    </div>
  );

  return (
    <div className="kpi-container">
      <div className="main-column">{firstColumnData.map(renderPorteContainer)}</div>
      <div className="main-column">{secondColumnData.map(renderPorteContainer)}</div>
    </div>
  );
};
