import React, { FC } from "react";
import { GATE_STATE } from "../helpers/types";

export const Scale: FC = ({}) => (
  <div className="marseille-page__gate-scale">
    <div className="marseille-page__gate-scale-column marseille-page__gate-scale-column-states">
      <div className={`marseille-page__gate-scale-column-state marseille-page__gate-scale-column-state--${GATE_STATE.EXCELLENT}`}></div>
      <div className={`marseille-page__gate-scale-column-state marseille-page__gate-scale-column-state--${GATE_STATE.GOOD}`}></div>
      <div className={`marseille-page__gate-scale-column-state marseille-page__gate-scale-column-state--${GATE_STATE.AVERAGE}`}></div>
      <div className={`marseille-page__gate-scale-column-state marseille-page__gate-scale-column-state--${GATE_STATE.MIDDLE}`}></div>
      <div className={`marseille-page__gate-scale-column-state marseille-page__gate-scale-column-state--${GATE_STATE.LOW}`}></div>
    </div>
    <div className="marseille-page__gate-scale-column marseille-page__gate-scale-column-text">
      <span>40</span>
      <span>35</span>
      <span>30</span>
      <span>25</span>
    </div>
  </div>
);
