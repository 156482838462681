/* eslint-disable max-len */
import React, { FC } from "react";

interface PropsLayer0 {
  vinotecaZoneColor: string;
  bufeSouthZoneColor: string;
  southZoneColor: string;
  consignaZoneColor: string;
  entertaimentZoneColor: string;
  northZoneColor: string;
  bufeNorthZoneColor: string;
  restaurantZoneColor: string;
}

export const Layer0Velasquez: FC<PropsLayer0> = ({
  bufeNorthZoneColor,
  bufeSouthZoneColor,
  consignaZoneColor,
  entertaimentZoneColor,
  northZoneColor,
  restaurantZoneColor,
  southZoneColor,
  vinotecaZoneColor,
}) => (
  <svg viewBox="0 0 396 1219" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ height: "100%", maxHeight: "100%", maxWidth: "100%" }}>
    <path d="M52 4L204 4V73L142 73V123H52L52 4Z" fill={vinotecaZoneColor} />
    <path d="M52 125L142 125V178H225V234L52 234V125Z" fill={bufeSouthZoneColor} />
    <path d="M52 237L225 237V282H174V332H161L161 410H52L52 237Z" fill={southZoneColor} />
    <path
      d="M52 413.5H161V504C161 506.209 159.209 508 157 508H139.728C137.341 508 135.052 507.052 133.364 505.364L126.636 498.636C124.948 496.948 122.659 496 120.272 496H108C103.029 496 99 500.029 99 505V520H52L52 413.5Z"
      fill={consignaZoneColor}
    />
    <path d="M52 523H99L99 649H52L52 523Z" fill={entertaimentZoneColor} />
    <path
      d="M52 604H99V604.888C99 609.093 102.41 612.503 106.615 612.503C108.485 612.503 110 614.018 110 615.887L110 710.286H161L161 790H52L52 604Z"
      fill={entertaimentZoneColor}
    />
    <path d="M56 793H161V854H175V926H219V1032V1044H182V1036H140V1058H136H52L52 793H56Z" fill={northZoneColor} />
    <path d="M52 1061L140 1061V1095H150V1161H265V1155.5H324V1199H52V1061Z" fill={bufeNorthZoneColor} />
    <path d="M265 1044V1153H316.327H316.857H318.973H321.435H383V1044H265Z" fill={restaurantZoneColor} />
  </svg>
);
