import React from "react";
import classnames from "classnames";
import { DefaultSiteInfo } from "./types";
import { RetailMetrics } from "@technis/shared";
import { useVisionData } from "../../../../hooks/useVisionData";

export const AirQualityPollutions = ({ installationId, organizationId, zoneId, hideTitle }: DefaultSiteInfo & { zoneId: number; hideTitle?: boolean }) => {
  const pm5 = useVisionData({ installationId, organizationId, zoneId, metric: RetailMetrics.PM2_5_LEVEL }) || 0;
  const pm10 = useVisionData({ installationId, organizationId, zoneId, metric: RetailMetrics.PM10_LEVEL }) || 0;
  const tvoc = useVisionData({ installationId, organizationId, zoneId, metric: RetailMetrics.TVOC }) || 0;

  return (
    <div className="air-quality">
      {!hideTitle && <h3 className="air-quality__title">Air quality</h3>}
      <div className="air-quality__cards">
        <div className="air-quality__card">
          <div
            className={classnames("air-quality__status", {
              "air-quality__status--moderate": pm5 >= 40 && pm5 < 150,
              "air-quality__status--correct": pm5 < 40,
              "air-quality__status--critical": pm5 >= 150,
            })}
          ></div>
          <div className="air-quality__row">
            <span className="value">{pm5}</span>
            <span className="unit">µg/m³</span>
          </div>
          <div className="air-quality__row">
            <span className="metric">PM5</span>
          </div>
        </div>
        <div className="air-quality__card">
          <div
            className={classnames("air-quality__status", {
              "air-quality__status--moderate": pm10 >= 150 && pm10 < 350,
              "air-quality__status--correct": pm10 < 150,
              "air-quality__status--critical": pm10 >= 350,
            })}
          ></div>
          <div className="air-quality__row">
            <span className="value">{pm10}</span>
            <span className="unit">µg/m³</span>
          </div>
          <div className="air-quality__row">
            <span className="metric">PM10</span>
          </div>
        </div>
        <div className="air-quality__card">
          <div
            className={classnames("air-quality__status", {
              "air-quality__status--moderate": tvoc >= 1 && tvoc < 10,
              "air-quality__status--correct": tvoc < 1,
              "air-quality__status--critical": tvoc >= 10,
            })}
          ></div>
          <div className="air-quality__row">
            <span className="value">{tvoc}</span>
            <span className="unit">mg/m³</span>
          </div>
          <div className="air-quality__row">
            <span className="metric">TVOC</span>
          </div>
        </div>
      </div>
    </div>
  );
};
