/* eslint-disable max-len */
import React from "react";
import TechnisSvg from "../assets/technis.svg";
import MapSvg from "../assets/map.svg";
import Map2Svg from "../assets/map2.svg";
import EnergySvg from "../assets/energy.svg";
import LoungeSvg from "../assets/lounge.svg";
import CinemaSvg from "../assets/cinema.svg";
import BouillonSvg from "../assets/bouillon.svg";

export const Technis = () => <img src={TechnisSvg} className="investor-page__technis" />;
export const Map = () => <img src={MapSvg} className="investor-page__map" />;
export const Map2 = () => <img src={Map2Svg} className="investor-page__map" />;
export const Energy = () => <img src={EnergySvg} className="investor-page__energy" />;
export const LoungeIcon = () => <img src={LoungeSvg} className="investor-page__icon" />;
export const CinemaIcon = () => <img src={CinemaSvg} className="investor-page__icon" />;
export const Bouillon = () => <img src={BouillonSvg} className="investor-page__bouillon" />;
