import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Date } from "./components/Date";
import { Card } from "./components/Card";
import { Gate } from "./components/Gate";
import { Scale } from "./components/Scale";

const DEFAULTS = {
  installationId: 2304,
  organizationId: 807,
};

export const Page = () => {
  const token = useSelector((state: RootState) => state.auth.token);

  return (
    <div className="marseille-page">
      <div className="marseille-page__header">
        <h1 className="marseille-page__header-title">Aéroport de Marseille | T2 - Low Cost</h1>
        <Date />
      </div>

      <div className="marseille-page__content">
        <div className="marseille-page__section">
          <h2 className="marseille-page__section-title">Postes d’Inspection/Filtrage</h2>
          <div className="marseille-page__gate-container">
            <div className="marseille-page__gate-items">
              <Gate passageId={7334} {...DEFAULTS} title="PIF 1" />
              <Gate passageId={7335} {...DEFAULTS} title="PIF 2" />
              <Gate passageId={7336} {...DEFAULTS} title="PIF 3" />
              <Gate passageId={7337} {...DEFAULTS} title="PIF 4" />
              <Gate passageId={7338} {...DEFAULTS} title="PIF 5" />
              <Gate passageId={7339} {...DEFAULTS} title="PIF 6" />
              <Gate passageId={7340} {...DEFAULTS} title="PIF 7" />
            </div>
            <Scale />
          </div>
        </div>
        <div className="marseille-page__section">
          <h2 className="marseille-page__section-title">Files d’attente</h2>
          <div className="marseille-page__cards">
            <Card zoneId={4641} title="File 1" {...DEFAULTS} token={token} />
            <Card zoneId={4642} title="File 2" {...DEFAULTS} token={token} />
            <Card zoneId={4649} title="File 3" {...DEFAULTS} token={token} />
          </div>
        </div>
      </div>

      <div className="marseille-page-powered-by-container">
        <span className="marseille-page-powered-by-container-text">powered by</span>
        <img src="https://merckserono-corsier.technis.com/76b9a76274db3a7d85786796322e35e3.svg" alt="technis logo" className="marseille-page-powered-by-container-logo" />
      </div>
    </div>
  );
};
