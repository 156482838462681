import React, { createContext, FunctionComponent, useEffect, useState } from "react";
import { useCookieTheme } from "../../hooks/token";
import { ThemeType, ThemeContextProvider as UIThemeContextProvider } from "@technis/ui";
import { ThemeConfiguration } from "@technis/shared";

interface Props {
  children: React.ReactNode;
  setRoot: boolean;
  defaultTheme?: AppThemes;
  themeConfiguration?: ThemeConfiguration;
}

export enum AppThemes {
  DARK = "dark",
  LIGHT = "light",
}

export const ThemeContext = createContext([] as unknown as [AppThemes, React.Dispatch<React.SetStateAction<AppThemes>>]);

export const ThemeContextProvider: FunctionComponent<Props> = (props: Props) => {
  const { children, defaultTheme = AppThemes.LIGHT, setRoot } = props;
  const [cookieTheme, setCookieTheme] = useCookieTheme();
  const [theme, setTheme] = useState(cookieTheme || defaultTheme);

  useEffect(() => {
    if (theme && setRoot) {
      document.documentElement.className = theme;
      setCookieTheme(theme);
    }
  }, [theme]);

  return (
    <UIThemeContextProvider defaultThemeVariant={ThemeType.LIGHT}>
      <ThemeContext.Provider value={[theme, setTheme]}>
        <div className="wrapper">{children}</div>
      </ThemeContext.Provider>
    </UIThemeContextProvider>
  );
};
