import React, { FC } from "react";
import { KeyIndicatorV2 } from "@technis/ui";
import { MetricsProps } from "../../../../common/types";

export const MetricsTopBottom: FC<MetricsProps> = ({ dataKPI }) => {
  const { capturebottom, capturetop, popularityBottom, poularitytop } = dataKPI.kpis;

  return (
    <div className="column-container">
      <div className="title-container">
        <span className="title-text">Ping-pong top zone</span>
      </div>
      <div className="kpi-column-container">
        <KeyIndicatorV2
          defaultIcon={{
            backgroundColor: "#A94133",
            iconColor: "#FFFFFF",
            iconName: "occupation-rate_custom",
          }}
          description="Capture rate"
          valueObject={{
            valueAsString: `${capturetop}%`,
          }}
        />
        <KeyIndicatorV2
          defaultIcon={{
            backgroundColor: "#B442B6",
            iconColor: "#FFFFFF",
            iconName: "popularity_custom",
          }}
          description="Popularity"
          valueObject={{
            valueAsString: `${poularitytop}%`,
          }}
        />
        <div className="title-container">
          <span className="title-text">Ping-pong bottom zone</span>
        </div>
        <KeyIndicatorV2
          defaultIcon={{
            backgroundColor: "#A94133",
            iconColor: "#FFFFFF",
            iconName: "occupation-rate_custom",
          }}
          description="Capture rate"
          valueObject={{
            valueAsString: `${capturebottom}%`,
          }}
        />
        <KeyIndicatorV2
          defaultIcon={{
            backgroundColor: "#B442B6",
            iconColor: "#FFFFFF",
            iconName: "popularity_custom",
          }}
          description="Popularity"
          valueObject={{
            valueAsString: `${popularityBottom}%`,
          }}
        />
      </div>
    </div>
  );
};
