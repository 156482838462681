// import { ID, Maybe, WebAppMetrics, isDefined } from "@technis/shared";
import { firebaseDb } from "../../../..";
import { useEffect, useRef, useState } from "react";
import { get, off, onValue, ref, set } from "firebase/database";
import { usePrevious } from "@technis/ui";
import { DISCONNECT_CHECK_INTERVAL } from "../../../../common/constants";

const url = "/production/ces2024";

export const useConversion = (token: string | undefined): number => {
  const [value, setValue] = useState<number>(0);

  const isConnectedRef = useRef(false);

  const startListening = () => {
    isConnectedRef.current = true;
    const countRef = ref(firebaseDb, url);

    onValue(
      countRef,
      (snapshot) => {
        const newValue = snapshot.val();

        setValue(newValue.conversionCount ?? 0);
      },
      (err) => {
        console.log(err);
        isConnectedRef.current = false;
      },
    );
  };

  const stopListening = () => {
    off(ref(firebaseDb, url));
    isConnectedRef.current = false;
  };

  const prevToken = usePrevious(token);

  // Logic to keep firebase alive
  useEffect(() => {
    if (prevToken && isConnectedRef.current) {
      stopListening();
    }
    startListening();
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isConnectedRef.current) {
        startListening();
      }
    }, DISCONNECT_CHECK_INTERVAL);
    return () => {
      clearInterval(interval);
      if (isConnectedRef.current) {
        stopListening();
      }
    };
  }, []);

  return value;
};

export const incrementConversionByOne = async () => {
  const reference = ref(firebaseDb, url);

  const { conversionCount } = (await get(reference)).val();

  await set(reference, { conversionCount: conversionCount + 1 });
};

export const decrementConversionByOne = async () => {
  const reference = ref(firebaseDb, url);

  const { conversionCount } = (await get(reference)).val();

  await set(reference, { conversionCount: conversionCount - 1 });
};

export const resetConversion = async () => {
  const reference = ref(firebaseDb, url);

  await set(reference, { conversionCount: 0 });
};
