import { Kpi } from "@technis/shared";
import gql from "graphql-tag";

export type KpisQueryResult = { kpisOccupancy: Kpi[] };
export const kpisQuery = gql`
  query kpis($zoneId: Int, $periodId: Int!) {
    kpisOccupancy(zoneId: $zoneId, periodId: $periodId) {
      dwell
      zoneId
      periodId
    }
  }
`;
