import { addKPIUnitByMetric, DataSources, ID, OccupancyColorimetry, OccupancySiteKpi, OccupancySiteZone, RetailMetrics, TechnisLogoColor, WebAppMetrics } from "@technis/shared";
import React, { FunctionComponent, memo } from "react";

import { v4 as uuidv4 } from "uuid";

import { i18n } from "../lang/i18n";
import { translation } from "../lang/translation";
import { useOccupancyData } from "../hooks/useOccupancyData";
import { useCount } from "../hooks/count";
import { useKpisQuery } from "../hooks/useKpisQuery";
import { kpiVisionIcons, kpiWebAppIcons } from "./helpers/zone";
import { ZoneWithMoreData } from "../common/types";
import { useVisionData } from "../hooks/useVisionData";
import { binaryOccupancyFormatter, comfortScoreFormatter, dwellFormatter, generalScoreFormatter, getValueWithUnit, NO_VALUE_PLACEHOLDER } from "./helpers/unit";
import { KeyIndicatorV2 } from "@technis/ui";

const DEFAULT_BACKGROUND_COLOR = "#639FFA";
const DEFAULT_ICON_COLOR = "#FFFFFF";

export type AllMetrics = RetailMetrics & WebAppMetrics;

const formatCountValue = (type: OccupancySiteKpi["type"], count = { in: 0, out: 0 }, limit: number) => {
  switch (type) {
    case WebAppMetrics.OCCUPANCY_RATE:
      return Number((((count.in - count.out) / limit) * 100).toFixed(1));
    case WebAppMetrics.AFFLUENCE_IN:
      return count.in;
    case WebAppMetrics.INSIDE:
      return count.in - count.out;
    case WebAppMetrics.WAITING_TIME:
      return undefined;
    case WebAppMetrics.DWELL_TIME:
      return undefined;
    case WebAppMetrics.LIMIT:
      return limit;
  }
};

const getColorimetryData = (colorimetry: OccupancyColorimetry[], kpiValue: number, iconColor: string) => {
  const result: { backgroundColor: string; color: string; text?: string } = { color: iconColor, backgroundColor: "" };

  colorimetry.forEach(({ values, color, text }, index) => {
    const [from, to] = values;

    if (kpiValue >= from && kpiValue <= to) {
      result.backgroundColor = color;
      result.text = text;
    }

    if (index === colorimetry.length - 1 && kpiValue > to) {
      result.backgroundColor = color;
      result.text = text;
    }
  });

  return result;
};

const { API, FIREBASE_LEGACY, FIREBASE_RETAIL, FIREBASE_VISION } = DataSources;

const getKpiConfig = (config: OccupancySiteKpi, value: number) => {
  const { rawValue, unit, valueAsJSX, valueAsString } = addKPIUnitByMetric({
    type: config.type,
    value,
    isLive: true,
    dwellFormatter,
    binaryOccupancyParams: {
      firstKey: translation.occupied,
      secondKey: translation.free,
      formatter: binaryOccupancyFormatter,
    },
    comfortScoreFormatter,
    scoreFormatter: generalScoreFormatter,
    getValueWithUnit,
    noValuePlaceHolder: NO_VALUE_PLACEHOLDER,
  });
  const allDescriptionsTranslation = { ...translation.descriptions.legacy, ...translation.descriptions.vision };
  const allIcons = { ...kpiWebAppIcons, ...kpiVisionIcons };
  const description = allDescriptionsTranslation[config.type];
  const iconName = allIcons[config.type];

  return { unit, defaultDesciption: i18n.t(description), value: rawValue, valueAsString, valueAsJSX, iconName };
};

type KpiDetailsProps = OccupancySiteKpi & {
  token?: string;
  zoneId: ID;
  periodId: ID;
  limit: number;
  zone: ZoneWithMoreData;
};

const KpiDetails = memo((config: KpiDetailsProps) => {
  const { type, description, colorimetry, dataSource, token, zoneId, periodId, limit, zone, customSubtitle } = config;
  const isDwellTime = type === WebAppMetrics.DWELL_TIME;

  const kpisFromApi = dataSource === API && isDwellTime ? useKpisQuery(zoneId, periodId) : [];
  const dwellValue = isDwellTime && kpisFromApi.find((kpi) => kpi.zoneId === zoneId && kpi.periodId === periodId)?.dwell;
  const limitValue = type === WebAppMetrics.LIMIT && limit;

  const apiValue = dwellValue || limitValue;
  const legacyValue = dataSource === FIREBASE_LEGACY ? useCount(token, periodId, zoneId, limit) : undefined;
  const retailValue = dataSource === FIREBASE_RETAIL ? useOccupancyData(token, zone, type) : undefined;
  const visionValue =
    dataSource === FIREBASE_VISION ? useVisionData({ zoneId, installationId: zone.installationId, metric: type, organizationId: zone.organizationId }) : undefined;

  const valueMap = {
    [FIREBASE_LEGACY]: formatCountValue(type, legacyValue ? legacyValue.count : { in: 0, out: 0 }, limit),
    [FIREBASE_RETAIL]: retailValue,
    [FIREBASE_VISION]: visionValue,
    [API]: apiValue,
  };

  const kpiValue = (apiValue ? apiValue : valueMap[dataSource]) as number;

  const { unit, defaultDesciption, valueAsJSX, valueAsString, iconName } = getKpiConfig(config, kpiValue);

  const colorimetryData = colorimetry && getColorimetryData(colorimetry, kpiValue, DEFAULT_ICON_COLOR);
  const subtitle = customSubtitle || zone.name;

  return (
    <div className="kpi-indicator-container">
      <KeyIndicatorV2
        valueObject={{ valueAsString, unit, valueAsJSX }}
        defaultIcon={{ iconName, backgroundColor: colorimetryData?.backgroundColor || DEFAULT_BACKGROUND_COLOR, iconColor: DEFAULT_ICON_COLOR }}
        description={description || defaultDesciption}
        badge={colorimetryData ? colorimetryData : undefined}
        subtitle={customSubtitle !== null ? subtitle : undefined}
      />
    </div>
  );
});

type ZoneWithKpisProps = {
  token?: string;
  zoneId: ID;
  periodId: number;
  name: string;
  limit: number;
  textColor: TechnisLogoColor;
  zone: ZoneWithMoreData;
} & Pick<OccupancySiteZone, "customTitle" | "kpis">;

export const ZoneWithKpis: FunctionComponent<ZoneWithKpisProps> = ({ token, zoneId, periodId, name, kpis, limit, textColor, zone, customTitle }) => {
  const kpisMap = kpis.map((kpi: OccupancySiteKpi) => <KpiDetails key={uuidv4()} {...kpi} token={token} zoneId={zoneId} periodId={periodId} limit={limit} zone={zone} />);

  const title = customTitle ?? name;
  return (
    <div className="zone">
      <div className="name-container">
        <span className={`name name${textColor === TechnisLogoColor.LIGHT ? "-light" : "-dark"}`}>{title}</span>
      </div>
      {kpisMap}
    </div>
  );
};
