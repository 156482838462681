import React, { FC } from "react";
import { useVisionData } from "../../../../hooks/useVisionData";
import { RetailMetrics } from "@technis/shared";
import { GATE_STATE } from "../helpers/types";

const getStateByValue = (value?: number): GATE_STATE => {
  if (!value || value < 3) {
    return GATE_STATE.OUT_OF_SERVICE;
  } else if (value >= 3 && value < 25) {
    return GATE_STATE.LOW;
  } else if (value >= 25 && value < 30) {
    return GATE_STATE.MIDDLE;
  } else if (value >= 30 && value < 35) {
    return GATE_STATE.AVERAGE;
  } else if (value >= 35 && value < 40) {
    return GATE_STATE.GOOD;
  }

  return GATE_STATE.EXCELLENT;
};

export const Gate: FC<{
  passageId: number;
  organizationId: number;
  installationId: number;
  title: string;
  token?: string;
}> = ({ passageId, title, installationId, organizationId }) => {
  const value = useVisionData({ installationId, organizationId, passageId, metric: RetailMetrics.AFFLUENCE_IN_ROLLING_SUM });

  const gateState = getStateByValue(value || 0);

  return (
    <div className="marseille-page__gate-items__item">
      <div className="marseille-page__gate-items__item-header">
        <h3 className="marseille-page__gate-items__item-title">{title}</h3>
        <p className={"marseille-page__gate-items__item-value"}>
          {gateState === GATE_STATE.OUT_OF_SERVICE ? "-" : value}
          <span> pers/15min</span>
        </p>
      </div>
      <div className={`marseille-page__gate-items__item-state marseille-page__gate-items__item-state--${gateState}`}></div>
    </div>
  );
};
