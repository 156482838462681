import React from "react";
import { i18n } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { cond, isNumber, matches } from "lodash";
import { RetailMetrics, WebAppMetrics } from "@technis/shared";

type ScoreMetrics =
  | RetailMetrics.COMFORT_SCORE
  | RetailMetrics.CO2_LEVEL_SCORE
  | RetailMetrics.HUMIDITY_LEVEL_SCORE
  | RetailMetrics.TEMPERATURE_SCORE
  | RetailMetrics.TVOC_SCORE
  | RetailMetrics.PM2_5_LEVEL_SCORE
  | RetailMetrics.PM10_LEVEL_SCORE
  | RetailMetrics.LUMINOSITY_SCORE
  | RetailMetrics.PRESSURE_SCORE;

export const NO_VALUE_PLACEHOLDER = "-";
const KGS_IN_TON = 1000;

export const getMaxScoreByScoreMetric = (metricType: ScoreMetrics) => {
  if (metricType === RetailMetrics.COMFORT_SCORE) {
    return 5;
  }

  return 3;
};

const convertKgsToTonnes = (physicalQuantity: string, value?: string | number, isPerSqareMeter?: boolean) => {
  const unit = isNumber(value) && value >= KGS_IN_TON ? "t" : "kg";
  const convertedValue = isNumber(value) && value >= KGS_IN_TON ? value / KGS_IN_TON : value;

  return { value: convertedValue, unit: `${unit}${physicalQuantity}${isPerSqareMeter ? "/m²" : ""}` };
};

/**
 * Selects the right unit for the given metric type passed.
 * The value can be supplied so that a different unit is selected when a value is too big
 */
export const getValueWithUnit = (type: RetailMetrics | WebAppMetrics, value?: number | string) => {
  const UnitMapper = cond<{ type: RetailMetrics | WebAppMetrics; value?: number | string }, { unit: string; value?: number | string }>([
    [matches({ type: WebAppMetrics.OCCUPANCY_RATE }), ({ value }) => ({ value, unit: i18n.t(translation.units.legacy[WebAppMetrics.OCCUPANCY_RATE]) })],
    [matches({ type: RetailMetrics.CAPTURE_RATE }), ({ value }) => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.CAPTURE_RATE]) })],
    [matches({ type: RetailMetrics.POPULARITY }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.POPULARITY]) })],
    [matches({ type: RetailMetrics.OCCUPANCY }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.OCCUPANCY]) })],
    [matches({ type: RetailMetrics.ABANDON_RATE_QUEUE }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.ABANDON_RATE_QUEUE]) })],
    [matches({ type: RetailMetrics.DWELL }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.DWELL]) })],
    [matches({ type: RetailMetrics.DWELL_QUEUE }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.DWELL_QUEUE]) })],
    [matches({ type: RetailMetrics.AFFLUENCE_IN_QUEUE }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.AFFLUENCE_IN_QUEUE]) })],
    [matches({ type: RetailMetrics.AFFLUENCE_OUT_QUEUE }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.AFFLUENCE_OUT_QUEUE]) })],
    [matches({ type: RetailMetrics.N_GROUPS_OUT }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.N_GROUPS_OUT]) })],
    [matches({ type: RetailMetrics.N_GROUPS_IN }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.N_GROUPS_IN]) })],
    [matches({ type: RetailMetrics.INSIDE_QUEUE }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.INSIDE_QUEUE]) })],
    [matches({ type: RetailMetrics.GROUP_SIZE_OUT }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.GROUP_SIZE_OUT]) })],
    [matches({ type: RetailMetrics.GROUP_SIZE_IN }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.GROUP_SIZE_IN]) })],
    [matches({ type: RetailMetrics.TEMPERATURE }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.TEMPERATURE]) })],
    [matches({ type: RetailMetrics.HUMIDITY_LEVEL }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.HUMIDITY_LEVEL]) })],
    [matches({ type: RetailMetrics.CO2_LEVEL }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.CO2_LEVEL]) })],
    [matches({ type: RetailMetrics.DEVICE_STATUS }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.DEVICE_STATUS]) })],
    [matches({ type: RetailMetrics.GROUP_PERCENTAGE }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.GROUP_PERCENTAGE]) })],
    [matches({ type: RetailMetrics.OCCUPANCY_STATUS }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.OCCUPANCY_STATUS]) })],
    [matches({ type: RetailMetrics.DENSITY }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.DENSITY]) })],
    [matches({ type: RetailMetrics.REVERSE_DENSITY }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.REVERSE_DENSITY]) })],
    [matches({ type: RetailMetrics.WAITING_TIME }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.WAITING_TIME]) })],
    [matches({ type: RetailMetrics.LIMIT }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.LIMIT]) })],
    [matches({ type: RetailMetrics.BINARY_OCCUPANCY }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.BINARY_OCCUPANCY]) })],
    [matches({ type: RetailMetrics.TVOC }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.TVOC]) })],
    [matches({ type: RetailMetrics.PM2_5_LEVEL }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.PM2_5_LEVEL]) })],
    [matches({ type: RetailMetrics.PM10_LEVEL }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.PM10_LEVEL]) })],
    [matches({ type: RetailMetrics.LUMINOSITY }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.LUMINOSITY]) })],
    [matches({ type: RetailMetrics.PRESSURE }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.PRESSURE]) })],
    [matches({ type: RetailMetrics.ELECTRICITY_PRODUCTION }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.ELECTRICITY_PRODUCTION]) })],
    [matches({ type: RetailMetrics.ELECTRICITY_PRODUCTION_ON_DAY }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.ELECTRICITY_PRODUCTION_ON_DAY]) })],
    [
      matches({ type: RetailMetrics.ELECTRICITY_PRODUCTION_ON_INTERVAL }),
      () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.ELECTRICITY_PRODUCTION_ON_INTERVAL]) }),
    ],
    [matches({ type: RetailMetrics.ELECTRICITY_CONSUMPTION }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.ELECTRICITY_CONSUMPTION]) })],
    [matches({ type: RetailMetrics.ELECTRICITY_CONSUMPTION_ON_DAY }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.ELECTRICITY_CONSUMPTION_ON_DAY]) })],
    [
      matches({ type: RetailMetrics.ELECTRICITY_CONSUMPTION_ON_INTERVAL }),
      () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.ELECTRICITY_CONSUMPTION_ON_INTERVAL]) }),
    ],
    [matches({ type: RetailMetrics.ELECTRICITY_PRODUCTION_PER_SQM }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.ELECTRICITY_PRODUCTION_PER_SQM]) })],
    [matches({ type: RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ]) })],
    [
      matches({ type: RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_DAY }),
      () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_DAY]) }),
    ],
    [
      matches({ type: RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_INTERVAL }),
      () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_INTERVAL]) }),
    ],
    [
      matches({ type: RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_PER_SQM }),
      () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_PER_SQM]) }),
    ],
    [matches({ type: RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ }), ({ value }) => convertKgsToTonnes(i18n.t(translation.descriptions.vision.CO2_LEVEL), value)],
    [matches({ type: RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_DAY }), ({ value }) => convertKgsToTonnes(i18n.t(translation.descriptions.vision.CO2_LEVEL), value)],
    [matches({ type: RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_INTERVAL }), ({ value }) => convertKgsToTonnes(i18n.t(translation.descriptions.vision.CO2_LEVEL), value)],
    [matches({ type: RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_PER_SQM }), ({ value }) => convertKgsToTonnes(i18n.t(translation.descriptions.vision.CO2_LEVEL), value, true)],
    [matches({ type: RetailMetrics.ELECTRICITY_CONSUMPTION_PER_SQM }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.ELECTRICITY_CONSUMPTION_PER_SQM]) })],
    [
      matches({ type: RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ }),
      () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ]) }),
    ],
    [
      matches({ type: RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_DAY }),
      () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_DAY]) }),
    ],
    [
      matches({ type: RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_INTERVAL }),
      () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]) }),
    ],
    [
      matches({ type: RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_PER_SQM }),
      () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_PER_SQM]) }),
    ],
    [matches({ type: RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_DAY }), ({ value }) => convertKgsToTonnes(i18n.t(translation.descriptions.vision.CO2_LEVEL), value)],
    [matches({ type: RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_INTERVAL }), ({ value }) => convertKgsToTonnes(i18n.t(translation.descriptions.vision.CO2_LEVEL), value)],
    [matches({ type: RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_PER_SQM }), ({ value }) => convertKgsToTonnes(i18n.t(translation.descriptions.vision.CO2_LEVEL), value, true)],
    [matches({ type: RetailMetrics.WATER_CONSUMPTION }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.WATER_CONSUMPTION]) })],
    [matches({ type: RetailMetrics.WATER_CONSUMPTION_PER_SQM }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.WATER_CONSUMPTION_PER_SQM]) })],
    [matches({ type: RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ]) })],
    [
      matches({ type: RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_PER_SQM }),
      () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_PER_SQM]) }),
    ],
    [matches({ type: RetailMetrics.WATER_CONSUMPTION_CO2_EQ }), ({ value }) => convertKgsToTonnes(i18n.t(translation.descriptions.vision.CO2_LEVEL), value)],
    [matches({ type: RetailMetrics.WATER_CONSUMPTION_CO2_EQ_PER_SQM }), ({ value }) => convertKgsToTonnes(i18n.t(translation.descriptions.vision.CO2_LEVEL), value, true)],
    [matches({ type: RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_DAY }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_DAY]) })],
    [
      matches({ type: RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_INTERVAL }),
      () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_INTERVAL]) }),
    ],
    [
      matches({ type: RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_DAY }),
      () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_DAY]) }),
    ],
    [
      matches({ type: RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_INTERVAL }),
      () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]) }),
    ],
    [matches({ type: RetailMetrics.WATER_CONSUMPTION_ON_DAY }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.WATER_CONSUMPTION_ON_DAY]) })],
    [matches({ type: RetailMetrics.WATER_CONSUMPTION_ON_INTERVAL }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.WATER_CONSUMPTION_ON_INTERVAL]) })],
    [matches({ type: RetailMetrics.COMBUSTIBLE_CONSUMPTION }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.COMBUSTIBLE_CONSUMPTION]) })],
    [matches({ type: RetailMetrics.COMBUSTIBLE_CONSUMPTION_PER_SQM }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.COMBUSTIBLE_CONSUMPTION_PER_SQM]) })],
    [
      matches({ type: RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ }),
      () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ]) }),
    ],
    [
      matches({ type: RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ_PER_SQM }),
      () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ_PER_SQM]) }),
    ],
    [matches({ type: RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ }), ({ value }) => convertKgsToTonnes(i18n.t(translation.descriptions.vision.CO2_LEVEL), value)],
    [matches({ type: RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ_PER_SQM }), ({ value }) => convertKgsToTonnes(i18n.t(translation.descriptions.vision.CO2_LEVEL), value, true)],
    [matches({ type: RetailMetrics.AFFLUENCE_IN }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.AFFLUENCE_IN]) })],
    [matches({ type: RetailMetrics.AFFLUENCE_OUT }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.AFFLUENCE_OUT]) })],
    [matches({ type: RetailMetrics.INSIDE }), () => ({ value, unit: i18n.t(translation.units.vision[RetailMetrics.INSIDE]) })],
    [matches({ type: RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ }), ({ value }) => convertKgsToTonnes(i18n.t(translation.descriptions.vision.CO2_LEVEL), value)],
    [matches({ type: RetailMetrics.COMFORT_SCORE }), () => ({ value, unit: `/${getMaxScoreByScoreMetric(RetailMetrics.COMFORT_SCORE)}` })],
    [matches({ type: RetailMetrics.CO2_LEVEL_SCORE }), () => ({ value, unit: `/${getMaxScoreByScoreMetric(RetailMetrics.CO2_LEVEL_SCORE)}` })],
    [
      matches({ type: RetailMetrics.HUMIDITY_LEVEL_SCORE }),
      () => ({
        value,
        unit: `/${getMaxScoreByScoreMetric(RetailMetrics.HUMIDITY_LEVEL_SCORE)}`,
      }),
    ],
    [
      matches({ type: RetailMetrics.TEMPERATURE_SCORE }),
      () => ({
        value,
        unit: `/${getMaxScoreByScoreMetric(RetailMetrics.TEMPERATURE_SCORE)}`,
      }),
    ],
    [matches({ type: RetailMetrics.TVOC_SCORE }), () => ({ value, unit: `/${getMaxScoreByScoreMetric(RetailMetrics.TVOC_SCORE)}` })],
    [
      matches({ type: RetailMetrics.PM2_5_LEVEL_SCORE }),
      () => ({
        value,
        unit: `/${getMaxScoreByScoreMetric(RetailMetrics.PM2_5_LEVEL_SCORE)}`,
      }),
    ],
    [
      matches({ type: RetailMetrics.PM10_LEVEL_SCORE }),
      () => ({
        value,
        unit: `/${getMaxScoreByScoreMetric(RetailMetrics.PM10_LEVEL_SCORE)}`,
      }),
    ],
    [
      matches({ type: RetailMetrics.LUMINOSITY_SCORE }),
      () => ({
        value,
        unit: `/${getMaxScoreByScoreMetric(RetailMetrics.LUMINOSITY_SCORE)}`,
      }),
    ],
    [matches({ type: RetailMetrics.PRESSURE_SCORE }), () => ({ value, unit: `/${getMaxScoreByScoreMetric(RetailMetrics.PRESSURE_SCORE)}` })],
  ]);
  return UnitMapper({ type, value });
};

export const dwellFormatter = (hours: number, minutes: number, seconds: number) =>
  hours || minutes || seconds ? (
    <>
      {!!hours && (
        <>
          <span>{hours}</span>
          <span> h </span>
        </>
      )}
      {!!minutes && (
        <>
          <span>{minutes}</span>
          <span> m </span>
        </>
      )}
      {!!seconds && (
        <>
          <span>{seconds}</span>
          <span> s</span>
        </>
      )}
    </>
  ) : (
    <>0</>
  );

export const binaryOccupancyFormatter = (key: string) => (
  <>
    <span>{i18n.t(key)}</span>
  </>
);

export const comfortScoreFormatter = (valueAsString: string) =>
  i18n.t(translation.scores.COMFORT_SCORE[valueAsString as unknown as keyof typeof translation.scores.COMFORT_SCORE], { defaultValue: "-" });
export const generalScoreFormatter = (valueAsString: string) =>
  i18n.t(translation.scores.GENERAL_SCORE[valueAsString as unknown as keyof typeof translation.scores.GENERAL_SCORE], { defaultValue: "-" });
