import { compose, AnyAction, applyMiddleware, createStore, Reducer } from "redux";
import { persistCombineReducers, persistStore } from "redux-persist";
import { PersistPartial } from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";
import { default as ReduxThunk } from "redux-thunk";

import { DEFAULT_AUTH_STATE, authReducer } from "./auth/auth.reducer";
import { DEFAULT_ROUTER_STATE, routerReducer } from "./router/router.reducer";

export const initialRootState = {
  auth: DEFAULT_AUTH_STATE,
  router: DEFAULT_ROUTER_STATE,
};

const rootReducer = {
  auth: authReducer,
  router: routerReducer,
};

const config = {
  key: "root",
  storage,
  whitelist: ["auth", "router"],
};

const reducer = persistCombineReducers(config, rootReducer) as Reducer<RootState & Partial<PersistPartial>, AnyAction>;

export type RootState = typeof initialRootState;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  const store = createStore(reducer, initialRootState, composeEnhancers(applyMiddleware(ReduxThunk)));
  const persistor = persistStore(store);
  return { store, persistor };
};

export const { store, persistor } = configureStore();
