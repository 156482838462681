import React, { FC } from "react";
import moment from "moment";
import { CalendarIcon } from "./Assets";

export const DateFoire: FC = () => {
  let today = moment().locale("fr").format("dddd D MMMM YYYY");
  today = today.charAt(0).toUpperCase() + today.slice(1);

  return (
    <div className="date-container">
      <CalendarIcon />
      {today}
    </div>
  );
};
