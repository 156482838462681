import { AreaChart_V2 } from "@technis/ui";
import React, { FC } from "react";

interface GraphProps {
  seriesData: (number | null)[];
  categories: string[];
}

export const Graph: FC<GraphProps> = ({ seriesData, categories }) => {
  const chartProps = {
    crosshairs: true,
    series: [{ data: seriesData }],
    xCategories: categories,
  };

  return (
    <div className="graph-container">
      <div className="title-container">
        <span className="title-text">Daily Occupancy Trends</span>
      </div>
      <div className="chart-wrapper">
        <AreaChart_V2 {...chartProps} />
      </div>
    </div>
  );
};
