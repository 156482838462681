import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { LogoMerck } from "./components/Assets";
import { Metrics } from "./components/Metrics";
import { Graph } from "./components/Graph";
import { useInsideKPI } from "./data/insideRestaurantA";
import "../../../styles/merckAubonne.scss";

export const Page = () => {
  const token = useSelector((state: RootState) => state.auth.token);

  const { seriesData, categories, occupancy } = useInsideKPI(token);

  return (
    <div className="merck-page">
      <div className="merck-page__logo-container">
        <LogoMerck />
      </div>
      <div className="merck-page__header">
        <span className="merck-page__header-title">Restaurant Aubonne</span>
        <div className="merck-page__content">
          <div className="merck-page__content-metrics-container">
            <Metrics insideKPI={occupancy} />
          </div>
          <div className="merck-page__content-graph-container">
            <Graph seriesData={seriesData} categories={categories} />
          </div>
        </div>
      </div>
      <div className="merck-page-powered-by-container">
        <span className="merck-page-powered-by-container-text">powered by</span>
        <img src="https://merckserono-corsier.technis.com/76b9a76274db3a7d85786796322e35e3.svg" alt="technis logo" className="merck-page-powered-by-container-logo" />
      </div>
    </div>
  );
};
