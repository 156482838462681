import React, { FC } from "react";

interface PropsLayer1 {
  leftZoneColor: string;
  middleZoneColor: string;
  rightZoneColor: string;
}

export const Layer1DaliVipOccupancy: FC<PropsLayer1> = ({ leftZoneColor, middleZoneColor, rightZoneColor }) => (
  <svg viewBox="0 0 1144 444" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
    <path d="M611.318 366.106V190.002V96.6877H783.5V197H980.5V96.6877H1098.16V402.59H620.451V367.509H611.318V366.106Z" fill={rightZoneColor} />
    <path d="M385.812 190.703H307.131V96.6875H607.807V190.703H557.226V214.558H390.027V190.703H385.812Z" fill={middleZoneColor} />
    <path d="M302.914 402.823H7.61133V51.7847H234.067V95.2845H302.914V402.823Z" fill={leftZoneColor} />
  </svg>
);
