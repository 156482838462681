/* eslint-disable max-len */
import React, { FC } from "react";
import { Layer0Velasquez } from "./map/Layer0";
import { Layer1Velasquez } from "./map/Layer1";
import { Layer2Velasquez } from "./map/Layer2";
import { TitleVelasquez } from "./map/Title";
import { LegendVelasquez } from "./map/Legend";
import { relative } from "path";

interface MasterLayerProps {
  result: {
    vinotecaZoneOccupancy: number;
    bufeSouthZoneOccupancy: number;
    southZoneOccupancy: number;
    consignaZoneOccupancy: number;
    entertaimentZoneOccupancy: number;
    northZoneOccupancy: number;
    bufeNorthZoneOccupancy: number;
    restaurantZoneOccupancy: number;
  };
}

const COLORS = ["#347D64", "#8E5708", "#75151F"];

const getColorForZone = (occupancy: number): string => {
  if (occupancy <= 40) return COLORS[0];
  if (occupancy >= 90) return COLORS[2];

  return COLORS[1];
};

const MAP_RATIO = 396 / 1219;

export const MasterLayer: FC<MasterLayerProps> = ({ result }) => {
  const {
    vinotecaZoneOccupancy,
    bufeSouthZoneOccupancy,
    southZoneOccupancy,
    consignaZoneOccupancy,
    entertaimentZoneOccupancy,
    northZoneOccupancy,
    bufeNorthZoneOccupancy,
    restaurantZoneOccupancy,
  } = result;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 20,
        maxWidth: "100vw",
        height: "100vh",
        overflow: "hidden",
        boxSizing: "border-box",
      }}
    >
      <div style={{ width: "100%", maxWidth: "100%", minHeight: "40px", position: "relative", aspectRatio: 581 / 112, boxSizing: "border-box" }}>
        <TitleVelasquez
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            boxSizing: "border-box",
            width: "100%",
            height: "100%",
          }}
        />

        <img
          // eslint-disable-next-line max-len
          src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Ff228d4e96ae72b5096dc80faffb81414.cdn.bubble.io%2Ff1677508233209x583146886436787500%2FGroup.png?w=128&h=29&auto=compress&dpr=1&fit=max"
          alt="Logo iberia"
          width={120}
          style={{
            position: "fixed",
            right: 20,
            top: 20,
          }}
        />
      </div>
      <div style={{ position: "relative", flex: 1, boxSizing: "border-box" }}>
        <Layer0Velasquez
          vinotecaZoneColor={getColorForZone(vinotecaZoneOccupancy)}
          bufeSouthZoneColor={getColorForZone(bufeSouthZoneOccupancy)}
          southZoneColor={getColorForZone(southZoneOccupancy)}
          consignaZoneColor={getColorForZone(consignaZoneOccupancy)}
          entertaimentZoneColor={getColorForZone(entertaimentZoneOccupancy)}
          northZoneColor={getColorForZone(northZoneOccupancy)}
          bufeNorthZoneColor={getColorForZone(bufeNorthZoneOccupancy)}
          restaurantZoneColor={getColorForZone(restaurantZoneOccupancy)}
        />
        <Layer1Velasquez />
        <Layer2Velasquez />
      </div>
      <LegendVelasquez />
    </div>
  );
};
