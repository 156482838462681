import { RetailMetrics, WebAppMetrics } from "@technis/shared";

const generatePath = <T extends RetailMetrics | WebAppMetrics>(enumValues: (RetailMetrics | WebAppMetrics)[], path: string): Record<T, string> =>
  enumValues.reduce((acc, key) => ({ ...acc, [key]: `${path}.${key}` }), {} as Record<T, string>);

export const translation = {
  notFound: "notFound",
  poweredBy: "poweredBy",
  persons: "persons",
  occupancyRateDescription: "occupancyRateDescription",
  affluenceInDescription: "affluenceInDescription",
  insideDescription: "insideDescription",
  waitingTimeDescription: "waitingTimeDescription",
  dwellTimeDescription: "dwellTimeDescription",
  limitDescription: "limitDescription",
  units: {
    legacy: generatePath<WebAppMetrics>(Object.values(WebAppMetrics), "units.legacy"),
    vision: generatePath<RetailMetrics>(Object.values(RetailMetrics), "units.vision"),
  },
  descriptions: {
    legacy: generatePath<WebAppMetrics>(Object.values(WebAppMetrics), "descriptions.legacy"),
    vision: generatePath<RetailMetrics>(Object.values(RetailMetrics), "descriptions.vision"),
  },
  free: "free",
  occupied: "occupied",
  scores: {
    COMFORT_SCORE: {
      1: "scores.COMFORT_SCORE.1",
      2: "scores.COMFORT_SCORE.2",
      3: "scores.COMFORT_SCORE.3",
      4: "scores.COMFORT_SCORE.4",
      5: "scores.COMFORT_SCORE.5",
    },
    GENERAL_SCORE: {
      1: "scores.GENERAL_SCORE.1",
      2: "scores.GENERAL_SCORE.2",
      3: "scores.GENERAL_SCORE.3",
    },
  },
};
