import React from "react";
import { FunnelCES } from "./components/Funnel";
import { LogoCES } from "./components/Assets";
import { MapCES } from "./components/Map";
import { useOldDaysQuery, useStatistics } from "./data/statistics";
import { decrementConversionByOne, incrementConversionByOne, resetConversion, useConversion } from "./data/conversion";
import { useSearchParams } from "react-router-dom";
import { CounterCES } from "./components/Counter";
import { useHeatmap } from "./data/heatmap";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

// Go to https://ces2024.technis.com/?isCounter=yes to show the counter manager instead of the webapp

export const Page = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const stats = useStatistics(token);
  const conversionCount = useConversion(token);
  const { imgSrc } = useHeatmap();
  const [searchParams] = useSearchParams();

  const cumulativeVisitorCount = useOldDaysQuery(3901);
  const cumulativeCaptureCount = useOldDaysQuery(3902);

  const isCounter = searchParams.get("isCounter") === "yes";

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100vw", height: "100vh", gap: 16, justifyContent: "space-evenly", backgroundColor: "#FFF" }}
    >
      {!isCounter && <div style={{ position: "absolute", padding: 16, right: 0, top: 0 }}>🔴 Live updated datas from CES visitors {}</div>}
      <LogoCES isCounter={!!isCounter} />
      {isCounter ? (
        <CounterCES count={conversionCount} onAdd={() => incrementConversionByOne()} onReset={() => resetConversion()} onRemove={() => decrementConversionByOne()} />
      ) : (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly", width: "100vw", gap: 16 }}>
          <FunnelCES {...stats} visitorCount={stats.visitorCount} captureCount={stats.captureCount} conversionCount={conversionCount} />
          <MapCES imgSrc={imgSrc} />
        </div>
      )}
      <img
        src="https://viparis-feb-prod-storage-cms.s3.eu-west-3.amazonaws.com/technis_logo_e7140c2687.png"
        alt="technis logo"
        height={50}
        onClick={() => incrementConversionByOne()}
      />
    </div>
  );
};
