import React from "react";
import { FunnelCES } from "./components/Funnel";
import { LogoCES, Map, Technis } from "./components/Assets";
import { MapCES } from "./components/Map";
import { useOldDaysQuery, useStatistics } from "./data/statistics";
import { decrementConversionByOne, incrementConversionByOne, resetConversion, useConversion } from "./data/conversion";
import { useSearchParams } from "react-router-dom";
import { CounterCES } from "./components/Counter";
import { useHeatmap } from "./data/heatmap";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import classNames from "classnames";

// Go to https://ces2024.technis.com/?isCounter=yes to show the counter manager instead of the webapp

export const Page = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const stats = useStatistics(token);
  const conversionCount = useConversion(token);
  const { imgSrc } = useHeatmap();
  const [searchParams] = useSearchParams();

  const cumulativeVisitorCount = useOldDaysQuery(3901);
  const cumulativeCaptureCount = useOldDaysQuery(3902);

  const isCounter = searchParams.get("isCounter") === "yes";

  return (
    <div className="ces2025__wrapper">
      <div className="ces2025__header">
        <Technis />
        <p>Optimise your physical spaces with reliable data.</p>
      </div>
      <div className={classNames("ces2025__container", { "ces2025__container--isCounter": isCounter })}>
        {isCounter ? (
          <CounterCES count={conversionCount} onAdd={() => incrementConversionByOne()} onReset={() => resetConversion()} onRemove={() => decrementConversionByOne()} />
        ) : (
          <>
            <div className="ces2025__left">
              <FunnelCES {...stats} visitorCount={stats.visitorCount} captureCount={stats.captureCount} conversionCount={conversionCount} />
            </div>
            <div className="ces2025__right">
              <Map />
            </div>
          </>
        )}
      </div>
      <div className="ces2025__footer">
        <LogoCES />
      </div>
    </div>
  );
};
