import { AuthAction, AuthActions } from "./auth.actions";

export interface AuthState {
  token?: string;
}

/** The default state of the authReducer. */
export const DEFAULT_AUTH_STATE: AuthState = {
  token: undefined,
};

/** The authReducer, handling the part of the state related to authentication context. */
export const authReducer = (state: AuthState = DEFAULT_AUTH_STATE, action: AuthAction): AuthState => {
  switch (action.type) {
    case AuthActions.AUTH_SAVE_TOKEN:
      return { ...state, token: action.token };
    default:
      return state;
  }
};
