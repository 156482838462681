import gql from "graphql-tag";

export type RenewTokenResult = { renew: string };
export const renewToken = gql`
  query renewToken {
    renew
  }
`;

export type LoginToApiResult = { loginOccupancy: string };
export const loginToApi = gql`
  query loginOccupancy($subdomain: String!) {
    loginOccupancy(subdomain: $subdomain)
  }
`;
