import { RetailMetrics, WebAppMetrics } from "@technis/shared";

import { IconName } from "@technis/ui";

export const kpiWebAppIcons: Record<WebAppMetrics, IconName> = {
  [WebAppMetrics.AFFLUENCE_IN]: IconName.AFFLUENCE_IN,
  [WebAppMetrics.DWELL_TIME]: IconName.DWELL_BASIC,
  [WebAppMetrics.INSIDE]: IconName.INSIDE,
  [WebAppMetrics.LIMIT]: IconName.HEART,
  [WebAppMetrics.OCCUPANCY_RATE]: IconName.OCCUPATION_RATE,
  [WebAppMetrics.WAITING_TIME]: IconName.WAITING_TIME,
};

export const kpiVisionIcons: Record<RetailMetrics, IconName> = {
  [RetailMetrics.DWELL]: IconName.DWELL_BASIC,
  [RetailMetrics.CAPTURE_RATE]: IconName.CAPTURE_RATE,
  [RetailMetrics.POPULARITY]: IconName.POPULARITY,
  [RetailMetrics.UNIQUE_VISITORS_IN]: IconName.AFFLUENCE_IN,
  [RetailMetrics.AFFLUENCE_IN]: IconName.AFFLUENCE_IN,
  [RetailMetrics.AFFLUENCE_OUT]: IconName.AFFLUENCE_OUT,
  [RetailMetrics.HUMIDITY_LEVEL]: IconName.DROPLET,
  [RetailMetrics.TEMPERATURE]: IconName.TEMPERATURE_HALF,
  [RetailMetrics.CO2_LEVEL]: IconName.CO2_LEVEL,
  [RetailMetrics.INSIDE]: IconName.INSIDE,
  [RetailMetrics.N_GROUPS_IN]: IconName.N_GROUPS_IN,
  [RetailMetrics.DENSITY]: IconName.USER_GROUP,
  [RetailMetrics.OCCUPANCY]: IconName.USER_GROUP,
  [RetailMetrics.GROUP_PERCENTAGE]: IconName.GROUP_SIZE_PERCENTAGE,
  [RetailMetrics.COMFORT_SCORE]: IconName.COMFORT_SCORE,
  [RetailMetrics.ABANDON_RATE_QUEUE]: IconName.ABANDON_RATE_QUEUE,
  [RetailMetrics.AFFLUENCE_IN_QUEUE]: IconName.AFFLUENCE_IN_QUEUE,
  [RetailMetrics.AFFLUENCE_OUT_QUEUE]: IconName.HEART,
  [RetailMetrics.BINARY_OCCUPANCY]: IconName.BINARY_OCCUPANCY,
  [RetailMetrics.CO2_LEVEL_SCORE]: IconName.CO2_LEVEL,
  [RetailMetrics.COMBUSTIBLE_CONSUMPTION]: IconName.FUEL_CONSUMPTION,
  [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ]: IconName.FUEL_CONSUMPTION_CO2_EQ,
  [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ_PER_SQM]: IconName.FUEL_CONSUMPTION_CO2_EQ,
  [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ]: IconName.FUEL_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ_PER_SQM]: IconName.FUEL_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.COMBUSTIBLE_CONSUMPTION_PER_SQM]: IconName.FUEL_CONSUMPTION,
  [RetailMetrics.DEVICE_STATUS]: IconName.HEART,
  [RetailMetrics.DWELL_QUEUE]: IconName.DWELL_QUEUE,
  [RetailMetrics.ELECTRICITY_CONSUMPTION]: IconName.ELECTRICITY_CONSUMPTION,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ]: IconName.ELECTRICITY_CONSUMPTION_CO2_EQ,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_DAY]: IconName.ELECTRICITY_CONSUMPTION_CO2_EQ,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_INTERVAL]: IconName.ELECTRICITY_CONSUMPTION_CO2_EQ,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_PER_SQM]: IconName.ELECTRICITY_CONSUMPTION_CO2_EQ,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ]: IconName.ELECTRICITY_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_DAY]: IconName.ELECTRICITY_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]: IconName.ELECTRICITY_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_PER_SQM]: IconName.ELECTRICITY_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_DAY]: IconName.ELECTRICITY_CONSUMPTION,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_INTERVAL]: IconName.ELECTRICITY_CONSUMPTION,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_PER_SQM]: IconName.ELECTRICITY_CONSUMPTION,
  [RetailMetrics.ELECTRICITY_PRODUCTION]: IconName.ELECTRICITY_PRODUCTION,
  [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ]: IconName.ELECTRICITY_PRODUCTION_CO2_EQ,
  [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_DAY]: IconName.ELECTRICITY_PRODUCTION_CO2_EQ,
  [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_INTERVAL]: IconName.ELECTRICITY_PRODUCTION_CO2_EQ,
  [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_PER_SQM]: IconName.ELECTRICITY_PRODUCTION_CO2_EQ,
  [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ]: IconName.ELECTRICITY_PRODUCTION_ENERGY_EQ,
  [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_DAY]: IconName.ELECTRICITY_PRODUCTION_ENERGY_EQ,
  [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_INTERVAL]: IconName.ELECTRICITY_PRODUCTION_ENERGY_EQ,
  [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_PER_SQM]: IconName.ELECTRICITY_PRODUCTION_ENERGY_EQ,
  [RetailMetrics.ELECTRICITY_PRODUCTION_ON_DAY]: IconName.ELECTRICITY_PRODUCTION,
  [RetailMetrics.ELECTRICITY_PRODUCTION_ON_INTERVAL]: IconName.ELECTRICITY_PRODUCTION,
  [RetailMetrics.ELECTRICITY_PRODUCTION_PER_SQM]: IconName.ELECTRICITY_PRODUCTION,
  [RetailMetrics.GROUP_SIZE_IN]: IconName.GROUP_SIZE_IN,
  [RetailMetrics.GROUP_SIZE_OUT]: IconName.GROUP_SIZE_OUT,
  [RetailMetrics.HUMIDITY_LEVEL_SCORE]: IconName.HUMIDITY_LEVEL,
  [RetailMetrics.INSIDE_QUEUE]: IconName.INSIDE_QUEUE,
  [RetailMetrics.LIMIT]: IconName.HEART,
  [RetailMetrics.LUMINOSITY]: IconName.LUMINOSITY,
  [RetailMetrics.LUMINOSITY_SCORE]: IconName.LUMINOSITY,
  [RetailMetrics.N_GROUPS_OUT]: IconName.N_GROUPS_OUT,
  [RetailMetrics.OCCUPANCY_STATUS]: IconName.HEART,
  [RetailMetrics.PM10_LEVEL]: IconName.PM10_LEVEL,
  [RetailMetrics.PM10_LEVEL_SCORE]: IconName.PM10_LEVEL,
  [RetailMetrics.PM2_5_LEVEL]: IconName.PM2_5_LEVEL,
  [RetailMetrics.PM2_5_LEVEL_SCORE]: IconName.PM2_5_LEVEL,
  [RetailMetrics.PRESSURE]: IconName.PRESSURE,
  [RetailMetrics.PRESSURE_SCORE]: IconName.PRESSURE,
  [RetailMetrics.REVERSE_DENSITY]: IconName.HEART,
  [RetailMetrics.TEMPERATURE_SCORE]: IconName.TEMPERATURE,
  [RetailMetrics.TVOC]: IconName.TVOC,
  [RetailMetrics.TVOC_SCORE]: IconName.TVOC,
  [RetailMetrics.WAITING_TIME]: IconName.WAITING_TIME,
  [RetailMetrics.WATER_CONSUMPTION]: IconName.WATER_CONSUMPTION,
  [RetailMetrics.WATER_CONSUMPTION_CO2_EQ]: IconName.WATER_CONSUMPTION_CO2_EQ,
  [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_PER_SQM]: IconName.WATER_CONSUMPTION_CO2_EQ,
  [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ]: IconName.WATER_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_PER_SQM]: IconName.WATER_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.WATER_CONSUMPTION_PER_SQM]: IconName.WATER_CONSUMPTION,
  [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_DAY]: IconName.WATER_CONSUMPTION_CO2_EQ,
  [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_INTERVAL]: IconName.WATER_CONSUMPTION_CO2_EQ,
  [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_DAY]: IconName.WATER_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]: IconName.WATER_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.WATER_CONSUMPTION_ON_DAY]: IconName.WATER_CONSUMPTION,
  [RetailMetrics.WATER_CONSUMPTION_ON_INTERVAL]: IconName.WATER_CONSUMPTION,
  [RetailMetrics.NUMBER_OF_REFUSAL]: IconName.HEART,
  [RetailMetrics.REFUSAL_RATE]: IconName.HEART,
  [RetailMetrics.HEAT_CONSUMPTION]: IconName.HEART,
  [RetailMetrics.HEAT_CONSUMPTION_ENERGY_EQ]: IconName.HEART,
  [RetailMetrics.HEAT_CONSUMPTION_CO2_EQ]: IconName.HEART,
  [RetailMetrics.HEAT_CONSUMPTION_PER_SQM]: IconName.HEART,
  [RetailMetrics.HEAT_CONSUMPTION_ENERGY_EQ_PER_SQM]: IconName.HEART,
  [RetailMetrics.HEAT_CONSUMPTION_CO2_EQ_PER_SQ]: IconName.HEART,
  [RetailMetrics.INSIDE_CORRECTED]: IconName.INSIDE,
  [RetailMetrics.DWELL_CORRECTED]: IconName.DWELL_BASIC,
  [RetailMetrics.AFFLUENCE_IN_CORRECTED]: IconName.AFFLUENCE_IN,
  [RetailMetrics.AFFLUENCE_OUT_CORRECTED]: IconName.AFFLUENCE_OUT,
  [RetailMetrics.OCCUPANCY_CORRECTED]: IconName.USER_GROUP,
  [RetailMetrics.REVERSED_DENSITY_CORRECTED]: IconName.USER_GROUP,
  [RetailMetrics.DENSITY_CORRECTED]: IconName.USER_GROUP,
  [RetailMetrics.DWELL_ABANDON]: IconName.HEART,
  [RetailMetrics.DWELL_WALKTHROUGH]: IconName.HEART,
  [RetailMetrics.NUMBER_OF_ABANDON]: IconName.N_OF_ABANDON,
  [RetailMetrics.NUMBER_OF_WALKTHROUGH]: IconName.N_OF_WALKTHROUGH,
  [RetailMetrics.ABANDON_RATE]: IconName.ABANDON_RATE,
  [RetailMetrics.CONVERSION_RATE]: IconName.CONVERSION_RATE,
  [RetailMetrics.AFFLUENCE_IN_ROLLING_SUM]: IconName.AFFLUENCE_IN,
};
