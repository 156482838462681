import React, { FC, useEffect, useState } from "react";
import moment from "moment";

export const Date: FC = () => {
  const [current, setCurrent] = useState(moment().locale("fr"));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(moment().locale("fr"));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const date = current.format("DD MMMM YYYY");
  const time = current.format("HH[h]mm");

  return (
    <div className="marseille-page__date">
      <span>{date}</span>
      <span>-</span>
      <span>{time}</span>
    </div>
  );
};
