import React from "react";
import classnames from "classnames";
import { DefaultSiteInfo } from "./types";
import { RetailMetrics } from "@technis/shared";
import { useVisionData } from "../../../../hooks/useVisionData";

export const AirQuality = ({ installationId, organizationId, zoneId }: DefaultSiteInfo & { zoneId: number }) => {
  const temperature = useVisionData({ installationId, organizationId, zoneId, metric: RetailMetrics.TEMPERATURE }) || 0;
  const co2 = useVisionData({ installationId, organizationId, zoneId, metric: RetailMetrics.CO2_LEVEL }) || 0;
  const humidity = useVisionData({ installationId, organizationId, zoneId, metric: RetailMetrics.HUMIDITY_LEVEL }) || 0;

  return (
    <div className="air-quality">
      <h3 className="air-quality__title">Air quality</h3>
      <div className="air-quality__cards">
        <div className="air-quality__card">
          <div
            className={classnames("air-quality__status", {
              "air-quality__status--moderate": false,
              "air-quality__status--correct": false,
              "air-quality__status--critical": false,
            })}
          ></div>
          <div className="air-quality__row">
            <span className="value">{temperature}</span>
            <span className="unit">°C</span>
          </div>
          <div className="air-quality__row">
            <span className="metric">Temperature</span>
          </div>
        </div>
        <div className="air-quality__card">
          <div
            className={classnames("air-quality__status", {
              "air-quality__status--moderate": humidity >= 60 && humidity < 70,
              "air-quality__status--correct": humidity < 60,
              "air-quality__status--critical": humidity >= 70,
            })}
          ></div>
          <div className="air-quality__row">
            <span className="value">{humidity}</span>
            <span className="unit">%</span>
          </div>
          <div className="air-quality__row">
            <span className="metric">Humidity</span>
          </div>
        </div>
        <div className="air-quality__card">
          <div
            className={classnames("air-quality__status", {
              "air-quality__status--moderate": co2 >= 1000 && co2 < 2000,
              "air-quality__status--correct": co2 < 1000,
              "air-quality__status--critical": co2 >= 2000,
            })}
          ></div>
          <div className="air-quality__row">
            <span className="value">{co2}</span>
            <span className="unit">ppm</span>
          </div>
          <div className="air-quality__row">
            <span className="metric">CO2 level</span>
          </div>
        </div>
      </div>
    </div>
  );
};
