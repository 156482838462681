import { useEffect, useRef, useState } from "react";
import { off, onValue, ref } from "firebase/database";
import { firebaseDb } from "../../../..";
import moment from "moment";
import gql from "graphql-tag";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { useQuery } from "react-apollo";

const TODAY = moment().format("YYYY-MM-DD");
const WOW_OFFICE_ZONE = 4119;
const PING_PONG_ZONE = 4120;
const BAR_ZONE = 4121;
const TOP_ZONE = 4126;
const BOTTOM_ZONE = 4127;

const GET_DATA = gql`
  query {
    segmentation: getKPIs(zoneId: ${WOW_OFFICE_ZONE}, kpiName: AFFLUENCE_IN, granularity: MINUTE, dateBegin: "${TODAY}", dateEnd: "${TODAY}", descriptors: { genders: MALE })

    capturetop: getKPIs(zoneId: ${TOP_ZONE}, kpiName: CAPTURE_RATE, granularity: MINUTE, 
      dateBegin: "${TODAY}", dateEnd: "${TODAY}"  )

    capturebottom: getKPIs(zoneId: ${BOTTOM_ZONE}, kpiName: CAPTURE_RATE, granularity: MINUTE, 
      dateBegin: "${TODAY}", dateEnd: "${TODAY}"  )

    popularitytop: getKPIs(zoneId: ${TOP_ZONE}, kpiName: POPULARITY, granularity: MINUTE, 
      dateBegin: "${TODAY}", dateEnd: "${TODAY}"  )

    popularitybottom: getKPIs(zoneId: ${BOTTOM_ZONE}, kpiName: POPULARITY, granularity: MINUTE, 
      dateBegin: "${TODAY}", dateEnd: "${TODAY}"  )
  }
`;

type ResultData = {
  segmentation: { values: { value: number; timestamp: string }[] };
  capturetop: { values: { value: number; timestamp: string }[] };
  capturebottom: { values: { value: number; timestamp: string }[] };
  popularitytop: { values: { value: number; timestamp: string }[] };
  popularitybottom: { values: { value: number; timestamp: string }[] };
};

const publicClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: "https://api-application.technis.com/api/v2/graphql",
  }),
});

function usePrevious(value: string | undefined): string | undefined {
  const ref = useRef<string | undefined>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useWowKPI = (token: string | undefined) => {
  const [occupancyOffice, setOccupancyOffice] = useState<number>(0);
  const [occupancyPingpong, setOccupancyPingpong] = useState<number>(0);
  const [occupancyBar, setOccupancyBar] = useState<number>(0);
  const occupancy = {
    office: occupancyOffice,
    pingpong: occupancyPingpong,
    bar: occupancyBar,
  };

  const [dwellOffice, setDwellOffice] = useState<number>(0);
  const [dwellPingpong, setDwellPingpong] = useState<number>(0);
  const [dwellBar, setDwellBar] = useState<number>(0);
  const dwell = {
    office: dwellOffice,
    pingpong: dwellPingpong,
    bar: dwellBar,
  };

  const [segmentation, setSegmentation] = useState<number>(0);
  const [captureRateTop, setcaptureRateTop] = useState<number>(0);
  const [captureRateBottom, setcaptureRateBottom] = useState<number>(0);
  const [popularityTop, setpopularityTop] = useState<number>(0);
  const [popularityBottom, setpopularityBottom] = useState<number>(0);
  const kpis = {
    segmentation: segmentation,
    capturetop: captureRateTop,
    capturebottom: captureRateBottom,
    poularitytop: popularityTop,
    popularityBottom: popularityBottom,
  };

  /////////////////

  const { data, startPolling, stopPolling, error } = useQuery<ResultData>(GET_DATA, {
    client: publicClient,
    fetchPolicy: "network-only",
    pollInterval: 600000,
    context: {
      headers: {
        Authorization:
          // eslint-disable-next-line max-len
          "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiIyTWhYWWV5aW1VV1dZZ0M3SEZlcFZSUXUzSzYzIiwiaXNzIjoiZmlyZWJhc2UtYWRtaW5zZGstZjhkYzhAdGVjaG5pcy1jb3VudGluZy5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInN1YiI6ImZpcmViYXNlLWFkbWluc2RrLWY4ZGM4QHRlY2huaXMtY291bnRpbmcuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTcxMDc1NDQxMCwiY2xhaW1zIjp7ImlzQXBpS2V5Ijp0cnVlLCJ0b2tlblR5cGUiOiJwdWJsaWMifX0.X0XZu3t1UhHX1mqfBagIRmZLtFtxXkcLMtNro3gV6-JJTjWnOySI8b94Dg-RICOd38wz8rqPOrF84wohmi2KDaXqRDlvJvKlnlct-1Us0dxlUGVgG1RXxabBRg0Q7LCCGqxfTbsPJBfPrqEc_5rJ9HHU-mGGqy7tbbdx8S5ooOypczrp48iZbt4ucxw6U7gEkcmz8-TJprMV2JP4eRj9wIHYfA4WakqQtiIMbLCE4ZLcngWFBQJBuUaypmoJnbQYrvQzt5vggLUhDAYonDXaXZ6aaK2HDZpjsFWNoJt_YPZepKgJ_Eukyq4uK0aQdvYDm4FtgMsFgcmDm6rKF93ggg",
      },
    },
    onError: (error) => console.error("GraphQL error:", error),
  });

  useEffect(() => {
    // every 1 minute
    startPolling(60000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  useEffect(() => {
    // When data is updated, update the state
    if (data && data.segmentation && data.capturetop && data.capturebottom) {
      const now = moment();
      const currentSegmentation = data.segmentation.values.find((item) => moment(item.timestamp).isSame(now, "minute"));
      const currentCaptureTop = data.capturetop.values.find((item) => moment(item.timestamp).isSame(now, "minute"));
      const currentCaptureBottom = data.capturebottom.values.find((item) => moment(item.timestamp).isSame(now, "minute"));
      const currentPopularityTop = data.popularitytop.values.find((item) => moment(item.timestamp).isSame(now, "minute"));
      const currentPopularityBottom = data.popularitybottom.values.find((item) => moment(item.timestamp).isSame(now, "minute"));

      setSegmentation(currentSegmentation?.value || 0);
      setcaptureRateTop(currentCaptureTop?.value || 0);
      setcaptureRateBottom(currentCaptureBottom?.value || 0);
      setpopularityTop(currentPopularityTop?.value || 0);
      setpopularityBottom(currentPopularityBottom?.value || 0);
    }
  }, [data]);

  ////////////////////////////////////

  const isConnectedRef = useRef(false);

  const url = "/production/liveKPIs/773/2104/zones";

  const startListening = () => {
    isConnectedRef.current = true;
    const liveDataRef = ref(firebaseDb, url);

    onValue(
      liveDataRef,
      (snapshot) => {
        const newValue = snapshot.val();

        const insideOffice = newValue[WOW_OFFICE_ZONE]["INSIDE"];
        const insidePingpong = newValue[BAR_ZONE]["INSIDE"];
        const insideBar = newValue[PING_PONG_ZONE]["INSIDE"];
        setOccupancyOffice(insideOffice);
        setOccupancyPingpong(insidePingpong);
        setOccupancyBar(insideBar);

        const dwellOffice = Math.round(newValue[WOW_OFFICE_ZONE]["DWELL"]);
        const dwellPingpong = Math.round(newValue[BAR_ZONE]["DWELL"]);
        const dwellBar = Math.round(newValue[PING_PONG_ZONE]["DWELL"]);
        setDwellOffice(dwellOffice);
        setDwellPingpong(dwellPingpong);
        setDwellBar(dwellBar);
      },
      (error) => {
        console.error(error);
        isConnectedRef.current = false;
      },
    );
  };

  const stopListening = () => {
    off(ref(firebaseDb, url));
    isConnectedRef.current = false;
  };

  const prevToken = usePrevious(token);

  useEffect(() => {
    if (prevToken && isConnectedRef.current) {
      stopListening();
      startListening();
    } else if (!isConnectedRef.current) {
      startListening();
    }

    return () => {
      if (isConnectedRef.current) {
        stopListening();
      }
    };
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isConnectedRef.current) {
        startListening();
      }
    }, 10000);

    return () => {
      clearInterval(interval);
      if (isConnectedRef.current) {
        stopListening();
      }
    };
  }, []);

  return {
    occupancy,
    dwell,
    kpis,
  };
};
