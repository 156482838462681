import React, { FC } from "react";
import { KeyIndicatorV2 } from "@technis/ui";

interface MetricsProps {
  insideKPI: number | undefined;
}

export const Metrics: FC<MetricsProps> = ({ insideKPI }) => {
  const occupancy = insideKPI ?? "0";

  return (
    <div>
      <div>
        <div className="title-container">
          <span className="title-text">Total capacity</span>
        </div>
        <KeyIndicatorV2
          defaultIcon={{
            backgroundColor: "#639FFA",
            iconColor: "#FFFFFF",
            iconName: "group_custom",
          }}
          description="Seating capacity inside restaurant"
          valueObject={{
            unit: "seats",
            valueAsString: "200",
          }}
        />
      </div>
      <div>
        <div className="title-container">
          <span className="title-text">Current occupancy</span>
        </div>
        <KeyIndicatorV2
          defaultIcon={{
            backgroundColor: "#639FFA",
            iconColor: "#FFFFFF",
            iconName: "inside_custom",
          }}
          description="Current number of people inside"
          valueObject={{
            unit: "people",
            valueAsString: `${occupancy}`,
          }}
        />
      </div>
    </div>
  );
};
