import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { LogoWhiteTechnis, LogoWow } from "./components/Assets";
import "../../../styles/wowShowroom.scss";
import { MetricsOffice } from "./components/metricsOffice";
import { MapWow } from "./components/map";
import { Labels } from "./components/labels";
import { MetricsPingpong } from "./components/metricsPingpong";
import { MetricsBar } from "./components/metricsBar";
import { useWowKPI } from "./data/wowShowroom";
import { MetricsTopBottom } from "./components/metricsTopBottom";

export const Page = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const wowData = useWowKPI(token);

  return (
    <div className="wow-page">
      <div className="wow-page__top-container">
        <div className="wow-page__logo-container">
          <LogoWow />
        </div>
        <div className="wow-page__top-container-content">
          <div>
            <MapWow />
          </div>
          <div>
            <Labels />
          </div>
        </div>
      </div>
      <div className="wow-page__bottom-container">
        <div className="wow-page__kpi-container">
          <MetricsOffice dataKPI={wowData} />
          <MetricsBar dataKPI={wowData} />
          <MetricsPingpong dataKPI={wowData} />
          <MetricsTopBottom dataKPI={wowData} />
        </div>
        <div className="wow-page-powered-by-container">
          <span className="merck-page-powered-by-container-text">Powered by</span>
          <LogoWhiteTechnis />
        </div>
      </div>
    </div>
  );
};
