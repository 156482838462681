import React, { FC } from "react";
import { MapLayer0, MapLayer1, MapLayer2 } from "./Map.utils";

interface Props {
  imgSrc: string | null;
}

export const MapCES: FC<Props> = ({ imgSrc }) => (
  <div style={{ aspectRatio: 1 / 1, position: "relative" }}>
    <MapLayer0 />
    {typeof imgSrc === "string" && <MapLayer1 imgSrc={imgSrc} />}
    <MapLayer2 />
  </div>
);
