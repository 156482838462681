import * as I18n from "i18n-js";

import { en } from "./en";
import { fr } from "./fr";

export const i18n = I18n;

i18n.locale = "fr";
i18n.fallbacks = false;
i18n.translations = { en, fr };

export enum SupportedLanguages {
  ENGLISH = "en",
  FRENCH = "fr",
}

export function setLanguage(language?: SupportedLanguages) {
  i18n.locale = language || SupportedLanguages.ENGLISH;
}
