import { ID } from "@technis/shared";
import { useQuery } from "react-apollo";
import { kpisQuery, KpisQueryResult } from "../graphql/kpi.gql";

const KPIS_POLL_INTERVAL = 60000;

export const useKpisQuery = (zoneId: ID, periodId: ID) => {
  const { data } = useQuery<KpisQueryResult>(kpisQuery, {
    fetchPolicy: "network-only",
    pollInterval: KPIS_POLL_INTERVAL,
    variables: {
      zoneId,
      periodId,
    },
  });

  return data?.kpisOccupancy || [];
};
